import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    Sentry.init({
        dsn: 'https://36df082e75bc4a7e8ae57f2187bb11ad@sentry.d-p-it.de/7',
    });

    if (window) {
        window.console.log = () => {};
        window.console.error = () => {};
        window.console.warn = () => {};
    }
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
