<!--<result></result>-->
<app-error-window></app-error-window>

<ion-content #contentElm>
    <ion-header shadow="none">
        <app-header></app-header>
    </ion-header>
    <div *ngIf="dataService.configuration">
        <step-maps
            *ngIf="dataService.devMode"
            class="card no-padding"></step-maps>

        <form-wizard
            *ngIf="
        !dataService.devMode &&
        dataService?.configuration?.portal?.order_steps?.length
      "
            #formWizard>
            <wizard-step
                *ngFor="
          let step of dataService?.configuration?.portal?.order_steps;
          let i = index
        "
                [title]="step.title | translate"
                [component]="step.component"
                (doPrev)="back(i)"
                (doNext)="next(i)">
                <app-step-service-select
                    *ngIf="step.component == 'service-select'"></app-step-service-select>
                <app-step-form-data
                    *ngIf="step.component == 'form-data'"></app-step-form-data>
                <step-maps
                    *ngIf="step.component == 'maps'"
                    class="card no-padding overflow-hidden"></step-maps>
                <app-step-summary
                    *ngIf="step.component == 'summary'"></app-step-summary>
            </wizard-step>
        </form-wizard>
    </div>
</ion-content>
