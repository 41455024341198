import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { DataImportPage } from './data-import.page';
import { PipesModule } from '../../pipes/pipes.module';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild(),
        TippyModule,
        PipesModule,
    ],
    declarations: [DataImportPage],
    exports: [DataImportPage],
})
export class DataImportPageModule {}
