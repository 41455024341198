import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { from, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
    constructor(private httpClient: HttpClient) {}

    async loadTranslation(lang: string) {
        const promise = new Promise<any>(async (resolve, reject) => {
            try {
                let translations: any = await lastValueFrom(
                    this.httpClient.get<any>(
                        `${environment.apiUrl}/i18n/order_${lang}.json`
                    )
                );
                resolve(translations);
            } catch (error) {
                reject(error);
            }
        });

        return promise;
    }

    getTranslation(lang: string) {
        return from(this.loadTranslation(lang));
    }
}
