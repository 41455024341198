import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HelpModal } from '../modal/help.modal';

@Injectable()
export class MapHelperService {
    constructor(
        private modalCtrl: ModalController,
        private translate: TranslateService
    ) {}

    initSearch(map: google.maps.Map, searchInput) {
        const autocomplete = new google.maps.places.Autocomplete(searchInput);
        autocomplete.bindTo('bounds', map);

        const infowindow = new google.maps.InfoWindow();
        const marker = new google.maps.Marker({
            position: { lat: 0, lng: 0 },
            map: map,
            anchorPoint: new google.maps.Point(0, -29),
        });

        autocomplete.addListener('place_changed', () => {
            infowindow.close();
            marker.setVisible(false);
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                window.alert(this.translate.instant('locationSelect'));
                return;
            }

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17); // Why 17? Because it looks good.
            }
            marker.setIcon(
                /** @type {google.maps.Icon} */ {
                    url: place.icon,
                    size: new google.maps.Size(71, 71),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(35, 35),
                }
            );
            marker.setPosition(place.geometry.location);
            marker.setVisible(true);
            let address = '';
            if (place.address_components) {
                address = [
                    (place.address_components[0] &&
                        place.address_components[0].short_name) ||
                        '',
                    (place.address_components[1] &&
                        place.address_components[1].short_name) ||
                        '',
                    (place.address_components[2] &&
                        place.address_components[2].short_name) ||
                        '',
                ].join(' ');
            }
            infowindow.setContent(
                '<div><strong>' + place.name + '</strong><br>' + address
            );
            infowindow.open(map, marker);
        });
    }

    openForm() {
        const element: HTMLElement = document.getElementById(
            'file-import'
        ) as HTMLElement;
        element.click();
    }

    async presentHelpPage() {
        const profileModal = await this.modalCtrl.create({
            component: HelpModal,
            componentProps: {
                userId: 8675309,
            },
        });
        profileModal.present();
    }
}
