<div class="head">
    <div
        *ngIf="dataService.configuration?.portal?.logo"
        class="head-title">
        <div
            class="main-logo"
            [ngStyle]="{
                'background-image':
                    'url(' + dataService.configuration?.portal?.logo + ')'
            }"></div>
    </div>
</div>
