import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-old-browser',
    templateUrl: './old-browser.page.html',
    styleUrls: ['./old-browser.page.scss'],
})
export class OldBrowserPage implements OnInit {
    constructor() {}

    ngOnInit() {}
}
