import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from 'src/services/data.service';

@Component({
    templateUrl: 'help.modal.html',
    styleUrls: ['./help.scss'],
})
export class HelpModal {
    constructor(public modalCtrl: ModalController, public data: DataService) {
        console.log(
            'data.orderData',
            this.data.orderData,
            this.data.orderDataServices
        );
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }
}
