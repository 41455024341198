<ion-grid>
    <ion-row>
        <ion-col
            size="12"
            size-md="6"
            size-lg="4"
            *ngFor="
                let portalService of sortByRank(
                    dataService.configuration?.portal?.portal_services
                )
            ">
            <image-box
                [itemsPerRow]="1"
                [portalService]="portalService"></image-box>
        </ion-col>
    </ion-row>
</ion-grid>
<div style="height: 110px; display: block"></div>
