<ion-content>
    <!--<div class="background-image"></div>-->
    <div class="center-content">
        <div class="white-container">
            <h1>{{ 'Thank you for your request!' | translate }}</h1>
            <p>{{ 'We will contact you as soon as possible.' | translate }}</p>

            <div class="mt-5">
                <ion-button
                    size="big"
                    color="light"
                    (click)="goToHome()">
                    {{ 'new request' | translate }}
                </ion-button>

                <ion-button
                    class="ml-3"
                    size="big"
                    color="light"
                    fill="outline"
                    (click)="goToOrder()">
                    {{ 'To request' | translate }}
                </ion-button>
            </div>
        </div>
    </div>
</ion-content>
