import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController, IonContent } from '@ionic/angular';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/services/data.service';

@Component({
    selector: 'app-data-import',
    templateUrl: './data-import.page.html',
    styleUrls: ['./data-import.page.scss'],
})
export class DataImportPage implements OnInit {
    @ViewChild(IonContent) content: IonContent;

    fields = [];
    usingCodes: any[];

    constructor(
        private loadingCtrl: LoadingController,
        private modalCtrl: ModalController,
        private toast: HotToastService,
        private http: HttpClient,
        public data: DataService,
        private ngZone: NgZone,
        private translate: TranslateService
    ) {}

    ngOnInit() {}

    async onFileChange(evt: any) {
        const loading = await this.loadingCtrl.create({
            message: this.translate.instant('listLoad'),
        });
        loading.present();
        try {
            const target: DataTransfer = <DataTransfer>evt.target;
            const documentData = new FormData();
            documentData.append('file', target.files[0]);
            await this.import(documentData);
        } catch (error) {
            this.toast.error(this.translate.instant('loadError'));
        } finally {
            loading.dismiss();
        }
    }

    async import(documentData) {
        const response: any = await this.http
            .post(
                this.data.configuration.portal_url + '/api/import',
                documentData
            )
            .toPromise();

        console.log('response', response);

        this.ngZone.runOutsideAngular(() => {
            for (const file of response) {
                for (let field of file.fields) {
                    if (field?.paths) {
                        let paths = field.paths.map(path => {
                            return {
                                lat: path.latitude,
                                lng: path.longitude,
                            };
                        });
                        // filter double lat lng from paths
                        paths = paths.filter((path, index) => {
                            return (
                                paths.findIndex(
                                    p =>
                                        p.lat === path.lat && p.lng === path.lng
                                ) === index
                            );
                        });

                        const fieldPolygon = new google.maps.Polygon({
                            paths,
                            editable: true,
                            draggable: false,
                            fillColor: '#fff',
                            fillOpacity: 0.2,
                            strokeColor: '#DC3B3B',
                            strokeWeight: 3,
                        });

                        delete field.paths;

                        const fieldCalculated = {
                            ...field,
                            fieldPolygon,
                            selected: true,
                        };
                        this.fields.push(fieldCalculated);
                        this.updateUsingCodeList();
                    }

                    // fieldPolygon.setMap(this.map);
                    /*this.bounds.union(
          this.fieldService.getBounds(fieldPolygon)
        );*/

                    /*this.fieldService.addField(
          fieldPolygon,
          field.name,
          field.size,
          field.note,
          this.map
        );*/
                }
            }
        });

        console.log('fields', this.fields);
        // this.map.fitBounds(this.bounds);
        // this.map.panToBounds(this.bounds);

        // this.chRef.detectChanges();

        return true;
    }

    save() {
        const fields = this.fields.filter(field => field.selected);
        this.modalCtrl.dismiss(fields);
    }

    close() {
        this.modalCtrl.dismiss();
    }

    updateUsingCodeList() {
        this.usingCodes = [];
        for (let field of this.fields) {
            field.usingCodes?.forEach(
                uC =>
                    this.usingCodes.indexOf(uC) === -1 &&
                    this.usingCodes.push(uC)
            );
        }
    }

    selectByUsingCode(value: string) {
        if (value == 'all') {
            this.fields.forEach(f => (f.selected = true));
            return;
        }
        if (value?.length) {
            this.fields.forEach(
                f => (f.selected = f.usingCodes?.indexOf(value) > -1)
            );
        }
    }
}
