<ion-header>
    <ion-toolbar color="primary">
        <ion-title> {{ 'help' | translate }} </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <div
        class="box"
        *ngFor="let service of data.orderDataServices">
        <h2>{{ service.title || service.service.title }}</h2>
        <div [innerHTML]="service.help_text | safe: 'html'"></div>
    </div>

    <div *ngIf="data.orderDataServices.length == 0">
        <h2>{{'proceedAsFollows'|translate}}</h2>
        <ol>
            <li>{{'searchArea'|translate}}</li>
            <li>
                {{'selectPolygon1'|translate}}<br />
                {{'selectPolygon2'|translate}}
            </li>
            <li>{{'areaInfo'|translate}}</li>
            <li>{{'furtherAreas'|translate}}</li>
            <li>{{'pressOK'|translate}}</li>
        </ol>
    </div>
</ion-content>
