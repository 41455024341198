<div>
    <div class="sticky top-0 z-50">
        <ul class="nav nav-justified">
            <li
                class="nav-item"
                *ngFor="let step of steps; let i = index"
                [ngClass]="{
          active: step.isActive,
          enabled: !step.isDisabled,
          disabled: step.isDisabled,
          completed: isCompleted
        }">
                <a (click)="goToStep(step)"
                    >{{ i + 1 }}. {{ step.title | translate }}</a
                >
            </li>
        </ul>
    </div>
    <div class="card-block">
        <ng-content></ng-content>
    </div>

    <div
        class="card-footer"
        [hidden]="isCompleted"
        ion-fixed>
        <ion-grid>
            <ion-row>
                <ion-col
                    class="ion-text-left"
                    size="3">
                    <ion-button
                        size="large"
                        color="primary"
                        (click)="previous()"
                        [hidden]="!hasPrevStep || !activeStep?.showPrev">
                        <ion-icon name="arrow-back-outline"></ion-icon>
                        {{ 'back' | translate }}
                    </ion-button>
                </ion-col>
                <!--<ion-col>
          <ion-button
            fill="clear"
            size="large"
            color="light"
            *ngIf="dataService.orderData?.service?.title"
            (click)="dataService.resetOrder()"
          >
            <ion-icon name="refresh"></ion-icon>
          </ion-button>
        </ion-col>-->

                <ion-col size="6">
                    <div
                        *ngIf="dataService.currentHelpText"
                        class="info-text">
                        {{ dataService.currentHelpText }}
                    </div>
                </ion-col>

                <ion-col
                    class="ion-text-right"
                    size="3">
                    <ion-button
                        size="large"
                        color="primary"
                        (click)="next()"
                        [disabled]="!activeStep?.isValid"
                        [hidden]="!hasNextStep || !activeStep?.showNext">
                        {{ 'next' | translate }}
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </ion-button>

                    <ion-button
                        size="large"
                        color="primary"
                        (click)="dataService.order()"
                        [hidden]="hasNextStep">
                        {{ dataService.configuration?.portal?.order_button_text
                        || ('request' | translate) }}
                    </ion-button>
                    <!--<ion-button fill="clear" size="large" color="light" (click)="complete()" [disabled]="!activeStep?.isValid" [hidden]="hasNextStep">
            <ion-icon name="done"></ion-icon>
          </ion-button>-->
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</div>
