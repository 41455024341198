import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/services/data.service';

@Component({
    selector: 'app-step-service-select',
    templateUrl: './step-service-select.component.html',
    styleUrls: ['./step-service-select.component.scss'],
})
export class StepServiceSelectComponent implements OnInit {
    constructor(public dataService: DataService) {}

    ngOnInit() {}

    sortByRank(data) {
        return data?.sort((a, b) => {
            return a?.rank - b?.rank;
        });
    }
}
