<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{ header }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="p-5">
        <div class="card">
            <p>{{ message }}</p>
            <div class="mt-3">
                <ng-container *ngFor="let input of inputs">
                    <ion-item
                        *ngIf="input.type == 'number' || input.type == 'text'">
                        <ion-label position="stacked">{{
                            input.placeholder
                        }}</ion-label>
                        <ion-input
                            [type]="input.type"
                            [(ngModel)]="input.value"
                            [placeholder]="input.placeholder"></ion-input>
                    </ion-item>
                </ng-container>
            </div>
            <ion-list *ngFor="let fieldData of additionalFieldData">
                <ion-radio-group
                    *ngIf="fieldData.dataType == 'radio'"
                    [(ngModel)]="fieldData.value">
                    <ion-list-header>
                        <ion-label>{{ fieldData.title }}</ion-label>
                    </ion-list-header>

                    <ion-item *ngFor="let choice of fieldData.choices">
                        <ion-radio
                            slot="start"
                            [value]="choice.id"></ion-radio>
                        <ion-label>{{ choice.title }}</ion-label>
                    </ion-item>
                </ion-radio-group>

                <ng-container *ngIf="fieldData.dataType == 'checkbox'">
                    <ion-list-header>
                        {{ fieldData.title }}
                    </ion-list-header>

                    <ion-item *ngFor="let choice of fieldData.choices">
                        <ion-checkbox
                            slot="start"
                            [value]="choice.id"
                            [(ngModel)]="choice.checked"></ion-checkbox>
                        <ion-label>{{ choice.title }}</ion-label>
                    </ion-item>
                </ng-container>

                <ion-item
                    *ngIf="
                        fieldData.dataType == 'number' ||
                        fieldData.dataType == 'text'
                    ">
                    <ion-label position="stacked">{{
                        fieldData.title
                    }}</ion-label>
                    <ion-input
                        [type]="fieldData.dataType"
                        [(ngModel)]="fieldData.value"
                        [placeholder]="fieldData.placeholder">
                    </ion-input>
                </ion-item>
            </ion-list>
        </div>
    </div>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="cancel()">{{
                'cancel' | translate
            }}</ion-button>
            <ion-button (click)="save()">{{ 'save' | translate }}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
