import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
    AlertController,
    LoadingController,
    ModalController,
    ToastController,
} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { FieldEditComponent } from 'src/components/field-edit/field-edit.component';
import { OrderData } from 'src/models/order-data.model';
import { PortalConfiguration } from 'src/models/portal-configuration.model';
import { PortalService } from 'src/models/portal-service.model';
import { WizardStepComponent } from '../components/wizard-step/wizard-step';
import { environment } from '../environments/environment';
import { EventsService } from './event.service';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    currentHelpText: string;
    lastPolygonNo = 0;
    selectedPolygon;

    gridCalculator = false;
    devMode = false;
    formData: UntypedFormGroup;
    agbAccepted = false;

    plannedDate?: string;
    plannedDateEnd?: string;

    steps: WizardStepComponent[] = [];

    orderData: OrderData = {
        company: null,
        firstName: null,
        lastName: null,
        email: null,
        street: null,
        houseNumber: null,
        zipCode: null,
        city: null,
        phoneNumber: null,
        polygons: [],
        service: {
            title: null,
        },
        portalService: null,
        portalServiceOptions: null,
        location: {
            title: null,
        },
        portalServiceOrganization: null,
    };

    orderDataServices: PortalService[] = [];

    configuration: PortalConfiguration = {};

    historyArray = [];
    loadingerror = false;

    selectedPartner: string = '';

    constructor(
        public http: HttpClient,
        public translate: TranslateService,
        public storage: Storage,
        public alertCtrl: AlertController,
        public events: EventsService,
        private loadingCtrl: LoadingController,
        private router: Router,
        private modalCtrl: ModalController,
        private toast: HotToastService
    ) {
        const urlSearchParams = new URL(location.href).searchParams;

        if (urlSearchParams.has('partner')) {
            this.selectedPartner = urlSearchParams.get('partner')?.toString();
        }
    }

    async order() {
        if (!this.agbAccepted) {
            const alert = await this.alertCtrl.create({
                header: this.translate.instant('Info'),
                message: this.translate.instant('please_accept_policy'),
                buttons: [
                    {
                        text: this.translate.instant('Ok'),
                        handler: () => {
                            console.log('Cancel clicked');
                        },
                    },
                ],
            });
            alert.present();

            return;
        }

        const orderResponses = await Promise.all(
            this.orderDataServices.map(portalService =>
                this.orderService(portalService)
            )
        );

        this.toast.success(this.translate.instant('Order sent successfully!'));
        // this.reset();

        const urlSearchParams = new URL(location.href).searchParams;
        this.router.navigateByUrl(
            'thank-you?bookingUrl=' +
                encodeURI(orderResponses[0].booking_url) +
                (urlSearchParams.has('portal')
                    ? `&portal=${urlSearchParams.get('portal')}`
                    : '') +
                (urlSearchParams.has('partner')
                    ? `&partner=${urlSearchParams.get('partner')}`
                    : '')
        );
    }

    async orderService(
        portalService: PortalService
    ): Promise<{ booking_url: string }> {
        const order = this.transFormMapsObject(this.orderData);
        order.portalService = portalService?.id;
        order.selectedOptions = portalService?.options?.filter(
            o => o.isChecked
        );
        order.service = portalService.service?.id;
        order.portalServiceOrganization =
            portalService.portalServiceOrganization;
        return new Promise(async (resolve, reject) => {
            const loading = await this.loadingCtrl.create();
            loading.present();

            try {
                const data: any = await this.http
                    .post(
                        this.configuration.portal_url + '/api/bookings/create',
                        order
                    )
                    .toPromise();

                loading.dismiss();

                resolve(data);
            } catch (errorData) {
                loading.dismiss();
                if (typeof errorData.error === 'object' || errorData.message) {
                    this.showAlert(
                        this.translate.instant('error'),
                        this.translate.instant('failed_to_order') +
                            (errorData.error.message || errorData.message),
                        [this.translate.instant('Ok')]
                    );
                }
                reject(errorData);
            }
        });
    }

    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    transFormMapsObject(data) {
        const saveData = Object.assign({}, data);

        const processPolygon = polygon => {
            if (polygon.gPaths !== undefined) {
                polygon.paths = polygon.gPaths.map(path => {
                    return { latitude: path.lat(), longitude: path.lng() };
                });
            } else {
                if (
                    polygon.polygon &&
                    typeof polygon.polygon.getPath === 'function'
                ) {
                    polygon.paths = polygon.polygon
                        .getPath()
                        .getArray()
                        .map(path => {
                            return {
                                latitude: path.lat(),
                                longitude: path.lng(),
                            };
                        });
                }
            }
            if (this.plannedDate?.length === 10) {
                polygon.plannedDate = this.plannedDate;
            }
            if (this.plannedDateEnd?.length === 10) {
                polygon.plannedDateEnd = this.plannedDateEnd;
            }
            delete polygon.gPaths;
            delete polygon.polygon;
            delete polygon.polygonId;
            if (polygon.children && polygon.children.length > 0) {
                for (const children of polygon.children) {
                    processPolygon(children);
                }
            }
        };

        for (const polygon of saveData.polygons.concat(
            saveData.children || []
        )) {
            processPolygon(polygon);
        }

        console.log(saveData);
        return saveData;
    }

    transStepsObject(steps) {
        const saveData = [];
        for (const step of steps) {
            saveData.push({
                hidden: step.hidden,
                isActive: step.isActive,
                isDisabled: step.isDisabled,
                isValid: step.isValid,
                showNext: step.showNext,
                showPrev: step.showPrev,
                title: step.title,
                _isActive: step._isActive,
            });
        }
        return saveData;
    }

    async showAlert(title, subTitle, buttonArray?, cssClass?) {
        const alert = await this.alertCtrl.create({
            header: title,
            subHeader: subTitle,
            buttons: buttonArray,
            cssClass: cssClass,
        });
        alert.present();
    }

    async editPolygonField(polygon, mode: 'create' | 'edit') {
        return new Promise(async (resolve, reject) => {
            let message;

            if (
                !this.orderData.service.configuration ||
                !this.orderData.service.configuration.hectare_disabled
            ) {
                const size = +(
                    google.maps.geometry.spherical.computeArea(
                        typeof polygon.polygon == 'object'
                            ? polygon.polygon.getPath()
                            : polygon.getPath()
                    ) / 10000
                ).toFixed(2);
                message = `${this.translate.instant('Calculated')}: ${size}ha`;
            }

            const header =
                this.orderData.service &&
                this.orderData.service.translations &&
                this.orderData.service.translations.fields
                    ? this.orderData.service.translations.fields
                    : null || mode == 'create'
                    ? this.translate.instant('add_field')
                    : this.translate.instant('change_field');

            const modal = await this.modalCtrl.create({
                component: FieldEditComponent,
                backdropDismiss: false,
                componentProps: {
                    header,
                    message,
                    polygon,
                    orderData: this.orderData,
                },
            });
            modal.onDidDismiss().then(async ({ data }) => {
                this.events.publish('maps:cdr');

                if (data.dismiss) {
                    reject(data);
                }

                resolve(data);
            });
            modal.present();
        });
    }

    relDiff(a, b) {
        return Math.abs((1 - a / b) * 100);
        //return 100 * Math.abs((a - b) / ((a + b) / 2));
    }

    async resetOrder() {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('Info'),
            message: this.translate.instant('resetOrderProcess'),
            buttons: [
                {
                    text: this.translate.instant('cancel'),
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
                {
                    text: this.translate.instant('yes'),
                    handler: () => {
                        this.reset();
                    },
                },
            ],
        });
        alert.present();
    }

    async reset() {
        window.location.reload();
        return;
        this.gridCalculator = false;
        this.events.publish('data:reset');

        for (const x in this.steps) {
            const _step = this.steps[x];
            _step.isActive = parseInt(x) === 0;
            _step.isDisabled = !_step.isActive;
        }

        this.orderData.polygons = [];
        this.orderData.service = { title: '' };
        this.orderData.portalService = null;
        this.orderData.location.title = null;
        this.orderData.portalServiceOrganization = null;
        //this.formData = null;

        await this.storage.remove('rr-result');
    }

    loadConfiguration() {
        return new Promise((res, reject) => {
            this.http.get(`${environment.apiUrl}/api/portal.json`).subscribe(
                portalConfiguration => {
                    console.log(portalConfiguration);
                    if (portalConfiguration) {
                        this.configuration.portal_url = environment.apiUrl;
                        this.configuration.portal = portalConfiguration;

                        // TODO Von API laden
                        // this.configuration.service_select_mode = 'single';

                        this.transformConfigData();
                        res(this.configuration);
                        return;
                    }
                    reject();
                    this.loadingerror = true;
                },
                error => {
                    console.log(error);
                    reject();
                    this.loadingerror = true;
                }
            );

            // jQuery.post('', {
            //   url: window.location != window.parent.location ? document.referrer : document.location.href
            // }, () => {
            //   res();
            // });
        });
    }

    isIE() {
        const userAgent = navigator.userAgent;
        return (
            userAgent.indexOf('MSIE ') > -1 ||
            userAgent.indexOf('Trident/') > -1 ||
            userAgent.indexOf('Edge/') > -1
        );
    }

    transformConfigData() {
        console.log('Partner', this.selectedPartner);
        this.configuration.portal.portal_services =
            this.configuration.portal.portal_services.filter(portalService => {
                // Filter for specific partner
                return (
                    !this.selectedPartner.length ||
                    !portalService.portal_service_organizations?.length ||
                    (this.selectedPartner.length === 36 &&
                        portalService.portal_service_organizations
                            ?.map(o => o.id)
                            ?.indexOf(this.selectedPartner) > -1) ||
                    portalService.portal_service_organizations
                        ?.map(o => o.title)
                        ?.indexOf(this.selectedPartner) > -1
                );
            });
        this.configuration.portal.portal_services.map(portalService => {
            portalService.portal_service_organizations =
                portalService.portal_service_organizations.filter(o => {
                    return (
                        !this.selectedPartner.length ||
                        (o.id?.toString()?.length === 36 &&
                            o.id?.toString().indexOf(this.selectedPartner) >
                                -1) ||
                        o.title.indexOf(this.selectedPartner) > -1
                    );
                });
            portalService.service.organizations =
                portalService.portal_service_organizations;
            portalService.service.organization_label =
                portalService.organization_label;
            portalService.service.grid_options_enabled =
                portalService.grid_options_enabled;
            portalService.service.help_text = portalService.help_text;

            // Bodenproben
            if (
                portalService.service.id ===
                '30303030-3030-3030-3030-303030303132'
            ) {
                portalService.service.configuration = {
                    fields_upload_disabled: false,
                    hectare_disabled: false,
                };
                return;
            }

            // Trichogramma Ausbringung
            if (
                portalService.service.id ===
                '30303030-3030-3030-3030-303030303031'
            ) {
                portalService.service.configuration = {
                    fields_upload_disabled: false,
                    hectare_disabled: false,
                };
                return;
            }

            if (
                // Maiszünsler
                portalService.service.id !==
                    '30303030-3030-3030-3030-303030303131' &&
                // Multispektralanalyse
                portalService.service.id !==
                    '30303030-3030-3030-3030-303030303038' /*&&
        portalService.service.id !== 'Kartografierung'*/
            ) {
                portalService.service.configuration = {
                    fields_upload_disabled: true,
                    hectare_disabled: true,
                };
                portalService.service.translations = {
                    fields: 'Objekte',
                };
            } else {
                /*portalService.service.configuration = {
          fields_upload_disabled: true,
          hectare_disabled: true,
        };
        portalService.service.translations = {
          fields: 'Objekte'
        };*/
            }
        });

        /**
         * Checking order steps
         */
        this.configuration.portal.order_steps =
            this.configuration.portal.order_steps || [];
        const steps: any = [
            {
                title: this.translate.instant('stepService'),
                rank: 1,
                component: 'service-select',
            },
            {
                title: this.translate.instant('stepFields'),
                rank: 3,
                component: 'maps',
            },
            {
                title: this.translate.instant('stepData'),
                rank: 2,
                component: 'form-data',
            },
            {
                title: this.translate.instant('stepSummary'),
                rank: 4,
                component: 'summary',
            },
        ];
        for (const step of steps) {
            if (
                !this.configuration.portal.order_steps.find(
                    s => s.component === step.component
                )
            ) {
                this.configuration.portal.order_steps.push(step);
            }
        }
        let r = 1;
        this.configuration.portal.order_steps.forEach(s => {
            s.rank = r++;
            if (s.component === 'service-select') {
                s.isValid = () => !!this.orderData?.service?.title;
            }
        });
    }
}
