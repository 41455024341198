<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{ 'Organisation' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content
    class="ion-padding"
    *ngIf="portalService">
    <div class="card">
        <h1>
            {{ portalService.organization_label || 'Organisation' }}
        </h1>

        <ng-select
            class="mt-5"
            [(ngModel)]="portalService.portalServiceOrganization"
            (ngModelChange)="selectOrganization($event)"
            [placeholder]="'select' | translate">
            <ng-option
                *ngFor="
                    let organization of portalService?.portal_service_organizations
                "
                [value]="organization.id">
                {{ organization.title }}</ng-option
            >
        </ng-select>
    </div>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button
                color="primary"
                fill="solid"
                (click)="close()"
                >{{ 'next' | translate }}</ion-button
            >
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
