<div [class.fullscreen-mode]="fullscreenMode">
    <ion-button
        class="fullscreenButton"
        color="light"
        size="big"
        fill="clear"
        (click)="fullscreenMode = !fullscreenMode">
        <ion-icon
            *ngIf="!fullscreenMode"
            name="expand"></ion-icon>
        <ion-icon
            *ngIf="fullscreenMode"
            name="contract"></ion-icon>
    </ion-button>

    <!--<div *ngIf="data.selectedPolygon" class="history-buttons">
    <ion-button color="light" fill="outline" (click)="goBack()">
      <ion-icon name="undo"></ion-icon>
    </ion-button>
    <ion-button color="light" fill="outline" (click)="goForward()">
      <ion-icon name="redo"></ion-icon>
    </ion-button>
  </div>-->

    <div class="sidebar-left">
        <div class="map-form-content">
            <ion-button (click)="tryGeolocation()"
                >{{'showLocation'|translate}}</ion-button
            >
            <h3>{{ 'search' | translate }}</h3>
            <input
                #searchInput
                class="form-control"
                type="text"
                [placeholder]="'search' | translate" />

            <h3 *ngIf="data.orderData?.service?.translations">
                {{ data.orderData.service?.translations?.fields || ('fields' |
                translate) }}
                <span *ngIf="data.orderData?.polygons?.length"
                    >- {{ data.orderData?.polygons?.length || '' }}</span
                >
            </h3>

            <ion-button
                class="ion-margin-bottom"
                size="small"
                expand="block"
                color="primary"
                (click)="importDialog()"
                [tippy]="'allowed_files' | translate">
                <ion-icon
                    slot="start"
                    name="cloud-upload"></ion-icon>
                {{ 'import_fields' | translate }}
            </ion-button>
            <field-list [map]="map"></field-list>

            <!--<ion-grid
        class="importfields"
        *ngIf="(!data.orderData.polygons.length || data.configuration.allow_import) && !data.orderData.service.configuration?.fields_upload_disabled"
      >
        <ion-row>
          <ion-col size="10"> </ion-col>
          <ion-col size="2" style="display: flex;">
            <ion-button
              style="margin-left: auto;"
              class="dark-button"
              size="small"
              fill="clear"
              color="light"
              placement="bottom"
              delay="200"
              tooltip="Erlaubte Dateitypen: XML, KML, SHP. ACHTUNG: Es werden nur die Formate ETRS89 und WGS84 unterstützt."
            >
              <ion-icon name="information-circle"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>-->

            <ion-button
                fill="outline"
                expand="block"
                margin-top
                (click)="mapHelper.presentHelpPage()"
                class="dark-button"
                color="light">
                {{ 'help' | translate }}
            </ion-button>
        </div>
    </div>

    <div class="map-wrapper">
        <div 
            id="map"
            #mapElement
            data-cy="map"></div>
        <ion-button
            *ngIf="fieldSaveButton"
            expand="block"
            class="save-button"
            (click)="setPolygon()"
            >{{ 'save field' | translate }}</ion-button
        >
    </div>

    <div
        *ngIf="data.gridCalculator && data.orderData.service?.grid_options_enabled"
        class="sidebar-right">
        <div class="grid-calculator">
            <div class="grid-options">
                <h2>{{ 'Grid options' | translate }}</h2>

                <!--<ion-button margin-top size="small" expand="block" (click)="calc()">Feld berechnen</ion-button>-->

                <ion-grid>
                    <ion-row>
                        <ion-col class="title">
                            {{ 'Partial areas' | translate }}
                        </ion-col>
                        <ion-col>
                            <ion-select
                                interface="popover"
                                placeholder="{{ 'Choose' | translate }}"
                                [(ngModel)]="fieldService.gridSize"
                                (ngModelChange)="fieldService.createSnapshot(); calc()">
                                <ion-select-option value="0"
                                    >{{ 'no' | translate }}</ion-select-option
                                >
                                <ion-select-option value="173.2050808"
                                    >3 {{ 'Hectares' | translate
                                    }}</ion-select-option
                                >
                                <ion-select-option value="223.6067977"
                                    >5 {{ 'Hectares' | translate
                                    }}</ion-select-option
                                >
                                <ion-select-option value="264.5751311"
                                    >7 {{ 'Hectares' | translate
                                    }}</ion-select-option
                                >
                                <ion-select-option value="316.227766"
                                    >10 {{ 'Hectares' | translate
                                    }}</ion-select-option
                                >
                            </ion-select>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col class="title">
                            {{ 'Move' | translate }} X
                        </ion-col>
                        <ion-col>
                            <app-stepper
                                [(ngModel)]="fieldService.offsetX"
                                (ngModelChange)="changeGridCalculatorValue('offsetX', $event)"></app-stepper>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col class="title">
                            {{ 'Move' | translate }} Y
                        </ion-col>
                        <ion-col>
                            <app-stepper
                                [(ngModel)]="fieldService.offsetY"
                                (ngModelChange)="changeGridCalculatorValue('offsetY', $event)"></app-stepper>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col class="title">
                            {{ 'Rotation' | translate }}
                        </ion-col>
                        <ion-col>
                            <app-stepper
                                [(ngModel)]="fieldService.degree"
                                (ngModelChange)="changeGridCalculatorValue('degree', $event)"></app-stepper>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
            <div
                class="grid-tools"
                *ngIf="fieldService.gridSize > 0">
                <h2>{{ 'Grid tools' | translate }}</h2>
                <ion-button
                    size="small"
                    expand="block"
                    (click)="moveIntersection(); activateSelectTool()"
                    >{{ 'Move intersection points' | translate }}</ion-button
                >
                <ion-button
                    size="small"
                    expand="block"
                    (click)="deleteIntersection(); activateSelectTool()"
                    >{{ 'Delete intersection points' | translate }}</ion-button
                >
                <ion-button
                    size="small"
                    expand="block"
                    (click)="addLine(); activateSelectTool()"
                    >{{ 'Add lines' | translate }}</ion-button
                >
                <ion-button
                    size="small"
                    expand="block"
                    (click)="deleteLine(); activateSelectTool()"
                    >{{ 'Delete lines' | translate }}</ion-button
                >
            </div>
        </div>

        <ion-button
            margin-top
            color="secondary"
            expand="block"
            (click)="data.gridCalculator = false"
            >{{ 'done' | translate }}</ion-button
        >
    </div>
</div>
