<div class="header">
    <div class="col"></div>
    <div class="col">{{ portalService.option_title }}</div>
    <div
        class="col"
        *ngFor="let property of portalService.property_titles">
        {{ property }}
    </div>
    <div *ngIf="checkbox"></div>
</div>
<ng-container *ngIf="environment.biocare">
    <ion-radio-group
        [(ngModel)]="selectedOption"
        (ngModelChange)="onSelectOption()">
        <div
            class="position"
            *ngFor="let option of portalService.options">
            <ng-container *ngIf="checkbox || option.isChecked">
                <div class="col">
                    <img [src]="option.picture" />
                </div>

                <div class="col">
                    {{ option.title }}
                </div>
                <div
                    class="col"
                    *ngFor="let property of option.properties">
                    {{ property }}
                </div>
                <div
                    class="col action-buttons"
                    *ngIf="checkbox">
                    <ion-radio [value]="option.id"></ion-radio>
                </div>
            </ng-container>
        </div>
    </ion-radio-group>
</ng-container>
<ng-container *ngIf="!environment.biocare">
    <div
        class="position"
        *ngFor="let option of portalService.options">
        <ng-container *ngIf="checkbox || option.isChecked">
            <div class="col">
                <img [src]="option.picture" />
            </div>

            <div class="col">
                {{ option.title }}
            </div>
            <div
                class="col"
                *ngFor="let property of option.properties">
                {{ property }}
            </div>
            <div
                class="col action-buttons"
                *ngIf="checkbox">
                <ion-checkbox
                    [(ngModel)]="option.isChecked"
                    (ngModelChange)="updateColumns()"></ion-checkbox>
            </div>
        </ng-container>
    </div>
</ng-container>