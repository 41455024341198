import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { PortalService } from 'src/models/portal-service.model';

@Component({
    selector: 'app-service-organisation',
    templateUrl: './service-organisation.component.html',
    styleUrls: ['./service-organisation.component.scss'],
})
export class ServiceOrganisationComponent implements OnInit {
    @Input() portalService: PortalService;

    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    selectOrganization(ev) {
        const organization =
            this.portalService?.portal_service_organizations.find(
                o => o.id == ev
            );
        this.portalService.portalServiceOrganizationTitle = organization.title;
        this.portalService.portalServiceOrganization =
            organization.id as string;
    }

    change(ev) {
        console.log(ev);
    }

    close() {
        this.modalCtrl.dismiss();
    }
}
