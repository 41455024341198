<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{ 'Service Options' | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content
    class="ion-padding"
    *ngIf="portalService">
    <div class="title ion-text-center">
        <h2>{{ portalService.title || portalService.service.title }}</h2>
        <p>
            {{ portalService.description || portalService.service.description }}
        </p>
    </div>

    <div
        class="card"
        *ngIf="portalService.options?.length">
        <h1>{{ 'detailSelection' | translate }}</h1>
        <p class="mb-3">
            {{ 'selectOptions' | translate }}
        </p>

        <app-service-options-table
            [portalService]="portalService"></app-service-options-table>
    </div>

    <div
        class="card"
        *ngIf="portalService.planned_date_enabled">
        <h1>{{ 'Mowing date' | translate }}</h1>

        <p>
            {{ 'selectMowingDate' | translate }}
        </p>
        <p>
            {{ 'thinkMowingDate' | translate }}
        </p>
        <div class="flex">
            <div class="flex flex-col">
                <span class="font-bold mb-1 text-gray-700 block">{{
                    'from' | translate
                }}</span>
                <input
                    type="date"
                    class="mr-5"
                    [(ngModel)]="dataService.plannedDate"
                    [ngModelOptions]="{ standalone: true }" />
            </div>
            <div class="flex flex-col">
                <span class="font-bold mb-1 text-gray-700 block">{{
                    'to' | translate
                }}</span>
                <input
                    type="date"
                    [(ngModel)]="dataService.plannedDateEnd"
                    [ngModelOptions]="{ standalone: true }" />
            </div>
        </div>
    </div>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button
                color="primary"
                fill="solid"
                (click)="close()"
                >{{ 'next' | translate }}</ion-button
            >
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
