<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{'import_fields'|translate}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="p-5">
        <input
            class="hidden"
            type="file"
            (change)="onFileChange($event)"
            multiple="false"
            #file />

        <ion-button
            color="primary"
            [tippy]="'allowed_files' | translate"
            (click)="file.click()">
            {{'selectFile'|translate}}
        </ion-button>
    </div>

    <div
        class="m-3 p-3 border rounded"
        *ngIf="usingCodes?.length">
        <div>{{'onlyUseCodes'|translate}}</div>

        <select
            class="w-auto border rounded"
            #sUsingCode
            (change)="selectByUsingCode(sUsingCode.value)">
            <option
                selected
                value="all">
                {{'all'|translate}}
            </option>
            <option
                *ngFor="let usingCode of usingCodes"
                [value]="usingCode">
                {{ usingCode | usingCode }}
            </option>
        </select>
    </div>

    <ion-list class="mt-5">
        <ion-item *ngFor="let field of fields">
            <ion-checkbox
                [(ngModel)]="field.selected"
                slot="start"></ion-checkbox>
            <ion-label>
                <h2>
                    <strong>{{'name'|translate}}:</strong> {{field.name || '-'}}
                </h2>
                <h2>
                    <strong>{{'size'|translate}}:</strong> {{field.size ||
                    '-'}}ha
                </h2>
                <h2 *ngIf="field.usingCodes?.length">
                    <strong>{{'useCodes'|translate}}:</strong
                    >&nbsp;<ng-container
                        *ngFor="let f of field.usingCodes; let last = last"
                        >{{ f | usingCode }}{{ !last ? ', ' : ''}}</ng-container
                    >
                </h2>
                <h2 *ngFor="let f of field.additionalFields">
                    <strong>{{ f.title }}:</strong> {{ f.value }}
                </h2>

                <!--<div>
          <textarea
            placeholder="Zusätzliche Informationen"
            [(ngModel)]="field.description"
          ></textarea>
        </div>-->

                <div
                    class="alert alert-danger"
                    *ngIf="field.error">
                    <strong>{{'error'|translate}}:</strong> {{field.error}}
                </div>
            </ion-label>
            <ion-icon
                slot="end"
                *ngIf="!field.status"
                name="checkmark-circle-outline"></ion-icon>
            <ion-icon
                slot="end"
                color="success"
                *ngIf="field.status == 'success'"
                name="checkmark-circle"></ion-icon>
            <ion-icon
                slot="end"
                color="danger"
                *ngIf="field.status == 'error'"
                name="close-circle"></ion-icon>
            <ion-spinner
                name="lines-small"
                *ngIf="field.status == 'loading'"></ion-spinner>
        </ion-item>
    </ion-list>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button
                color="primary"
                fill="solid"
                (click)="save()"
                >{{'addFields'|translate}}</ion-button
            >
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
