import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/services/data.service';

@Component({
    selector: 'app-error-window',
    templateUrl: './error-window.component.html',
    styleUrls: ['./error-window.component.scss'],
})
export class ErrorWindowComponent implements OnInit {
    constructor(public dataService: DataService, private router: Router) {}

    ngOnInit() {}

    oldBrowser() {
        this.router.navigateByUrl('old-browser');
    }
}
