import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { StepperComponent } from './stepper.component';

@NgModule({
    declarations: [StepperComponent],
    imports: [IonicModule, FormsModule, CommonModule],
    exports: [StepperComponent],
})
export class StepperComponentModule {}
