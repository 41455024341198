import {
    Component,
    Output,
    EventEmitter,
    ContentChildren,
    QueryList,
    AfterContentInit,
} from '@angular/core';
import { WizardStepComponent } from '../wizard-step/wizard-step';
import { DataService } from '../../services/data.service';
import { EventsService } from 'src/services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { HotToastService } from '@ngneat/hot-toast';
import { WizardStepComponentType } from 'src/common/interfaces';
@Component({
    selector: 'form-wizard',
    templateUrl: './wizard.html',
    styleUrls: ['./wizard.scss'],
})
export class WizardComponent implements AfterContentInit {
    @ContentChildren(WizardStepComponent)
    wizardSteps: QueryList<WizardStepComponent>;

    private _isCompleted = false;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output()
    onStepChanged: EventEmitter<WizardStepComponent> =
        new EventEmitter<WizardStepComponent>();

    constructor(
        public dataService: DataService,
        public events: EventsService,
        private translate: TranslateService,
        private toast: HotToastService
    ) {
        events.subscribe('data:reset', () => {
            this._isCompleted = false;
        });
    }

    ngAfterContentInit() {
        this.wizardSteps.forEach(step => {
            let foundStep = null;
            // eslint-disable-next-line guard-for-in
            for (const x in this.dataService.steps) {
                const _step = this.dataService.steps[x];
                if (_step.title === step.title) {
                    step.hidden = _step.hidden;
                    step.isDisabled = _step.isDisabled;
                    step.isValid = _step.isValid;
                    step.showNext = _step.showNext;
                    step.showPrev = _step.showPrev;
                    step.title = _step.title;
                    step.isActive = _step.isActive;
                    this.dataService.steps[x] = step;
                    foundStep = step;
                }
            }

            if (foundStep === null) {
                this.dataService.steps.push(step);
            }
        });
        this.steps[0].isActive = true;
    }

    public get steps(): Array<WizardStepComponent> {
        return this.dataService.steps.filter(step => !step.hidden);
    }

    public get isCompleted(): boolean {
        return this._isCompleted;
    }

    public get activeStep(): WizardStepComponent {
        return this.steps.find(step => step.isActive);
    }

    public set activeStep(step: WizardStepComponent) {
        if (step !== this.activeStep && !step.isDisabled) {
            this.activeStep.isActive = false;
            step.isActive = true;
            this.onStepChanged.emit(step);
        }
    }

    public get activeStepIndex(): number {
        return this.steps.indexOf(this.activeStep);
    }

    public get hasNextStep(): boolean {
        return this.activeStepIndex < this.steps.length - 1;
    }

    public get hasPrevStep(): boolean {
        return this.activeStepIndex > 0;
    }

    goToStep(step: WizardStepComponent) {
        if (!this.isCompleted) {
            this.activeStep = step;
        }
    }

    next() {
        console.log(this.activeStep);
        if (this.hasNextStep) {
            if (
                this.activeStep.component == WizardStepComponentType.Data &&
                !this.dataService.formData.valid
            ) {
                this.toast.warning(
                    this.translate.instant(
                        'Please fill out all fields correctly'
                    )
                );
                return;
            }

            if (
                this.activeStep.component == WizardStepComponentType.Service &&
                this.dataService.orderDataServices?.length == 0
            ) {
                this.toast.warning(this.translate.instant('selectService'));
                return;
            }

            if (
                this.activeStep.component == WizardStepComponentType.Fields &&
                this.dataService.orderData?.polygons?.length == 0
            ) {
                this.toast.warning(this.translate.instant('selectField'));
                return;
            }

            const nextStep: WizardStepComponent =
                this.steps[this.activeStepIndex + 1];
            this.activeStep.doNext.emit();
            nextStep.isDisabled = false;

            this.activeStep = nextStep;
        }
    }

    previous() {
        if (this.hasPrevStep) {
            const prevStep: WizardStepComponent =
                this.steps[this.activeStepIndex - 1];
            this.activeStep.doPrev.emit();
            prevStep.isDisabled = false;
            this.activeStep = prevStep;
        }
    }

    complete() {
        this.activeStep.doComplete.emit();
        this._isCompleted = true;
    }
}
