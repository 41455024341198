<div class="card">
    <h2>{{ 'Data' | translate }}</h2>

    <form
        [formGroup]="dataService.formData"
        class="custom-form"
        autocomplete="on"
        ion-grid>
        <ion-item>
            <ion-input
                name="company"
                formControlName="company"
                [placeholder]="'Company' | translate"
                type="text"></ion-input>
        </ion-item>

        <ion-row>
            <ion-col size="6">
                <ion-item>
                    <ion-input
                        name="firstName"
                        formControlName="firstName"
                        [placeholder]="'first Name' | translate"
                        type="text"></ion-input>
                </ion-item>
            </ion-col>
            <ion-col size="6">
                <ion-item>
                    <ion-input
                        name="lastName"
                        formControlName="lastName"
                        [placeholder]="'last Name' | translate"
                        type="text"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="9">
                <ion-item>
                    <ion-input
                        name="street"
                        formControlName="street"
                        [placeholder]="'street' | translate"
                        type="text"></ion-input>
                </ion-item>
            </ion-col>
            <ion-col size="3">
                <ion-item>
                    <ion-input
                        name="houseNumber"
                        formControlName="houseNumber"
                        [placeholder]="'house number' | translate"
                        type="text"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="4">
                <ion-item>
                    <ion-input
                        name="zipCode"
                        formControlName="zipCode"
                        [placeholder]="'post code' | translate"
                        type="text"></ion-input>
                </ion-item>
            </ion-col>
            <ion-col size="8">
                <ion-item>
                    <ion-input
                        name="city"
                        formControlName="city"
                        [placeholder]="'city' | translate"
                        type="text"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="6">
                <ion-item>
                    <ion-input
                        name="phoneNumber"
                        formControlName="phoneNumber"
                        [placeholder]="'phone number' | translate"
                        type="tel">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size="6">
                <ion-item>
                    <ion-input
                        name="email"
                        formControlName="email"
                        [placeholder]="'Email address' | translate"
                        type="email"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
    </form>
</div>
