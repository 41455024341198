import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    NgZone,
    ViewChild,
} from '@angular/core';
import {
    AlertController,
    LoadingController,
    ModalController,
    ToastController,
} from '@ionic/angular';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { DataImportPage } from 'src/components/data-import/data-import.page';
import { Polygon } from 'src/models/polygon.model';
import { DataService } from 'src/services/data.service';
import { EventsService } from 'src/services/event.service';
import { MapConfig } from './config/map.config';
import { FieldService } from './services/field.service';
import { MapHelperService } from './services/map-helper.service';
import Marker = google.maps.Marker;

@Component({
    selector: 'step-maps',
    templateUrl: 'step-maps.html',
    styleUrls: ['./step-maps.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepMapsComponent implements AfterViewInit {
    fullscreenMode = false;
    polySnapper: any;
    fieldSaveButton = false;
    text: string;
    // @ts-ignore
    map: google.maps.Map;

    debounceSubject: Subject<any> = new Subject<any>();

    @ViewChild('mapElement', { static: true }) map_element: ElementRef;
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    mapinterval: any;

    geocoder: google.maps.Geocoder;
    bounds: google.maps.LatLngBounds;

    polygonList: any[] = [];

    currentPositionMarker: google.maps.Marker;

    @HostListener('window:keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.ctrlKey && event.key == 'y') {
            event.preventDefault();
            this.goForward();
        }

        if (event.ctrlKey && event.key == 'z') {
            event.preventDefault();
            this.goBack();
        }
    }

    constructor(
        public zone: NgZone,
        public http: HttpClient,
        private chRef: ChangeDetectorRef,
        public translate: TranslateService,
        public data: DataService,
        public modalCtrl: ModalController,
        public alertCtrl: AlertController,
        public mapHelper: MapHelperService,
        public fieldService: FieldService,
        private loadingCtrl: LoadingController,
        private events: EventsService,
        private toast: HotToastService
    ) {
        this.geocoder = new google.maps.Geocoder();
        this.bounds = new google.maps.LatLngBounds();

        this.debounceSubject
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((model: any) => {
                if (model.mode == 'offsetX') {
                    this.fieldService.offsetX = model.event;
                }
                if (model.mode == 'offsetY') {
                    this.fieldService.offsetY = model.event;
                }
                if (model.mode == 'degree') {
                    this.fieldService.degree = model.event;
                }
                this.calc();
            });

        this.events.subscribe('data:reset', () => {
            this.fieldService.deleteFields();
        });
        this.events.subscribe('maps:cdr', () => {
            this.chRef.detectChanges();
        });
    }

    async goBack() {
        console.log('goBack');
        this.data.selectedPolygon.children.forEach(subPolygon => {
            subPolygon.polygon.setMap(null);
        });
        this.data.selectedPolygon = await _.clone(
            this.data.historyArray[this.data.historyArray.length - 1]
        );
        console.log(this.data.selectedPolygon);
        this.calc();

        // this.fieldService.addField(this.data.historyArray[this.data.historyArray.length - 1], data.label, data.size, this.map);
    }

    goForward() {
        console.log('goForward');
    }

    changeGridCalculatorValue(mode, event) {
        const model = {
            mode: mode,
            event: event,
        };
        this.debounceSubject.next(model);
    }

    ngAfterViewInit() {
        this.mapinterval = setInterval(() => {
            if (this.map_element.nativeElement.offsetParent != null) {
                clearInterval(this.mapinterval);
                this.initGoogle();
            }
        }, 50);
    }

    initDevData() {
        if (this.data.devMode) {
            this.data.orderData.city = this.translate.instant('orderCity');
            this.data.orderData.zipCode = 12345;
            this.data.orderData.company = '';
            this.data.orderData.email = 'asd@asd.de';
            this.data.orderData.firstName =
                this.translate.instant('orderFirsName');
            this.data.orderData.houseNumber = 1;
            this.data.orderData.street = this.translate.instant('orderStreet');
            this.data.orderData.lastName =
                this.translate.instant('orderLastName');
            this.data.orderData.phoneNumber = '012345';
            this.data.orderData.polygons = [
                {
                    label: 'Test',
                    size: 10,
                    paths: [
                        new google.maps.LatLng(50.947189, 13.105822),
                        new google.maps.LatLng(50.947568, 13.100314),
                        new google.maps.LatLng(50.948222, 13.094736),
                        new google.maps.LatLng(50.949418, 13.087591),
                        new google.maps.LatLng(50.947019, 13.086579),
                        new google.maps.LatLng(50.942928, 13.085465),
                        new google.maps.LatLng(50.942336, 13.087559),
                        new google.maps.LatLng(50.941689, 13.087347),
                        new google.maps.LatLng(50.942031, 13.085629),
                        new google.maps.LatLng(50.940605, 13.085511),
                        new google.maps.LatLng(50.939769, 13.085928),
                        new google.maps.LatLng(50.93884, 13.088726),
                        new google.maps.LatLng(50.937776, 13.091707),
                        new google.maps.LatLng(50.937887, 13.093849),
                        new google.maps.LatLng(50.93769, 13.096539),
                        new google.maps.LatLng(50.939689, 13.099849),
                        new google.maps.LatLng(50.939874, 13.098109),
                        new google.maps.LatLng(50.940338, 13.098048),
                        new google.maps.LatLng(50.940355, 13.098527),
                        new google.maps.LatLng(50.940107, 13.098993),
                        new google.maps.LatLng(50.940987, 13.100285),
                        new google.maps.LatLng(50.941286, 13.099092),
                        new google.maps.LatLng(50.942732, 13.099945),
                        new google.maps.LatLng(50.943571, 13.100284),
                        new google.maps.LatLng(50.943274, 13.102405),
                        new google.maps.LatLng(50.943682, 13.103328),
                        new google.maps.LatLng(50.944823, 13.10317),
                        new google.maps.LatLng(50.945583, 13.10342),
                        new google.maps.LatLng(50.945629, 13.104548),
                        new google.maps.LatLng(50.94592, 13.104602),
                        new google.maps.LatLng(50.946127, 13.104105),
                        new google.maps.LatLng(50.946255, 13.104609),
                        new google.maps.LatLng(50.946709, 13.105531),
                        new google.maps.LatLng(50.947189, 13.105822),
                    ].map(a => ({
                        latitude: a.lat(),
                        longitude: a.lng(),
                    })),
                },
            ];
        }
    }

    initGoogle() {
        this.initDevData();
        this.initMaps();
        /*if (
      !!this.data.orderData.zipCode &&
      !!this.data.orderData.city
    ) {
      const address =
        this.data.orderData.zipCode +
        ' ' +
        this.data.orderData.city;
      this.geocoder.geocode(
        {
          address: address,
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            MapConfig.center = results[0].geometry.location;
          }


        }
      );
    }*/
    }

    setPolygon(polygon?: any) {
        polygon = polygon || this.polySnapper.polygon();
        this.data.editPolygonField(polygon, 'edit').then(
            async (data: any) => {
                polygon.editable = false;
                await this.fieldService.addField(
                    polygon,
                    data.label,
                    data.size,
                    data.note,
                    polygon.usingCodes,
                    polygon.additionalFields
                );
                this.chRef.detectChanges();
                // this.restartPolySnapper();
            },
            () => {
                polygon.setMap(null);
                // this.restartPolySnapper();
            }
        );
    }

    activateSelectTool() {
        const dragButton: any = document.querySelector(
            '#map .gmnoprint div[role="button"]:first-of-type'
        );
        dragButton.click();
    }

    restartPolySnapper() {
        this.polySnapper.disable();
        this.polySnapper.enable();
        this.fieldSaveButton = false;
        this.chRef.detectChanges();
    }
    async tryGeolocation() {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            const infoWindow = new google.maps.InfoWindow();
            navigator.geolocation.getCurrentPosition(
                position => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    if (!this.currentPositionMarker) {
                        this.currentPositionMarker = new google.maps.Marker({
                            position: pos,
                            map: this.map,
                            title: this.translate.instant('actualPosition'),
                        });
                    } else {
                        this.currentPositionMarker.setPosition(pos);
                    }

                    this.map.setCenter(pos);
                },
                () => {
                    console.log('No Geolocation Detected');
                    this.toast.warning(this.translate.instant('geoLocation'));
                }
            );
        } else {
            // Browser doesn't support Geolocation
            console.log('No Geolocation Detected');
            this.toast.warning(this.translate.instant('geoLocation'));
        }
    }

    async initMaps() {
        this.map = await new google.maps.Map(
            this.map_element.nativeElement,
            MapConfig.config
        );

        this.tryGeolocation();

        this.mapHelper.initSearch(this.map, this.searchInput.nativeElement);

        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            },
            polygonOptions: {
                editable: true,
                draggable: true,
                fillColor: '#fff',
                fillOpacity: 0.2,
                strokeColor: '#DC3B3B',
                strokeWeight: 3,
            },
        });
        drawingManager.setMap(this.map);

        google.maps.event.addListener(
            drawingManager,
            'polygoncomplete',
            polygon => {
                this.data.editPolygonField(polygon, 'create').then(
                    (data: any) => {
                        this.fieldService.addField(
                            polygon,
                            data.label,
                            data.size,
                            data.note,
                            polygon.usingCodes,
                            polygon.additionalFields
                        );
                        this.chRef.detectChanges();
                    },
                    () => {
                        polygon.setMap(null);
                    }
                );
            }
        );

        /*this.polySnapper = ps.PolySnapper({
		map: this.map,
		threshold: 20,
		key: 'shift',
		keyRequired: true,
		polygons: [],
		hidePOI: true,
		polystyle: {
			fillColor: '#fff',
			fillOpacity: 0.2,
			strokeColor: '#DC3B3B',
			strokeWeight: 3

		},
		onChange: () => {
			this.fieldSaveButton = true;
			this.chRef.detectChanges();
		}
	});

	this.polySnapper.enable();*/

        /** Load polygons */

        for (const polygon of this.data.orderData.polygons) {
            if (typeof polygon.gPaths === 'undefined') {
                /** Add to map */
                polygon.polygonId = this.data.lastPolygonNo++;

                polygon.polygon = new google.maps.Polygon({
                    paths: polygon.paths.map(
                        path =>
                            new google.maps.LatLng(
                                parseFloat(path.latitude.toString()),
                                parseFloat(path.longitude.toString())
                            )
                    ),
                    editable: true,
                    draggable: true,
                    fillColor: '#fff',
                    fillOpacity: 0.2,
                    strokeColor: '#DC3B3B',
                    strokeWeight: 3,
                });
                polygon.polygon.setMap(this.map);
                this.bounds.union(this.fieldService.getBounds(polygon.polygon));

                const somethingChanged = () => {
                    console.log('somethingChan ged', polygon);
                    polygon.gPaths = polygon.polygon.getPath().getArray();
                };

                google.maps.event.addListener(
                    polygon.polygon.getPath(),
                    'insert_at',
                    somethingChanged
                );
                google.maps.event.addListener(
                    polygon.polygon.getPath(),
                    'set_at',
                    somethingChanged
                );
                google.maps.event.addListener(
                    polygon.polygon.getPath(),
                    'dragend',
                    somethingChanged
                );
            }
        }

        if (this.data.orderData.polygons.length > 0) {
            this.map.fitBounds(this.bounds);
            this.map.panToBounds(this.bounds);
        }

        setTimeout(() => {
            google.maps.event.addListener(this.map, 'idle', () => {
                google.maps.event.trigger(this.map, 'resize');
            });
        });
    }

    async calc() {
        console.log(this.data.selectedPolygon);
        this.clearGridCaluclator();
        const loading = await this.loadingCtrl.create();
        loading.present();
        const polyData = this.data.selectedPolygon;

        polyData.childrenOptions = {
            gridSize: this.fieldService.gridSize,
            offsetX: this.fieldService.offsetX,
            offsetY: this.fieldService.offsetY,
            degree: this.fieldService.degree,
        };

        this.fieldService.splitField(polyData).then(
            () => loading.dismiss(),
            () => loading.dismiss()
        );
    }

    clearGridCaluclator() {
        this.fieldService.markers.forEach(marker => {
            marker.marker.setMap(null);
        });
    }

    moveIntersection() {
        const points = this.startEditing(this.data.selectedPolygon);
        for (const pointData of points) {
            const point = pointData.marker;
            point.setDraggable(true);
            let moveThisPoints = [];
            google.maps.event.addListener(point, 'dragstart', event => {
                // Search all affected polygons
                moveThisPoints = this.fieldService.markers.filter(
                    m =>
                        m.marker &&
                        m.marker.getPosition() &&
                        m.marker.getPosition().lat() ===
                            point.getPosition().lat() &&
                        m.marker.getPosition().lng() ===
                            point.getPosition().lng()
                );
                console.log('Dragstart', event);
                // subPolygon.polygon.getPath().setAt(i, event.latLng);
            });

            google.maps.event.addListener(point, 'drag', event => {
                console.log('Drag', event);
                for (const lPoint of moveThisPoints) {
                    lPoint.polygon.polygon
                        .getPath()
                        .setAt(lPoint.position, event.latLng);
                    if (lPoint.marker !== point) {
                        lPoint.marker.setPosition(event.latLng);
                    }
                }
            });

            google.maps.event.addListener(point, 'dragend', event => {
                console.log('Dragend', event);
                this.fieldService.createSnapshot();
                // subPolygon.polygon.getPath().setAt(i, event.latLng);
                moveThisPoints = [];
            });
        }
        return false;
    }

    deleteIntersection() {
        const doIt = () => {
            const points = this.startEditing(this.data.selectedPolygon);
            for (const pointData of points) {
                const point = pointData.marker;
                google.maps.event.addListener(point, 'click', async () => {
                    // Search all affected polygons
                    const pointsForDeletion = this.fieldService.markers.filter(
                        m =>
                            m.marker &&
                            m.marker.getPosition() &&
                            m.marker.getPosition().lat() ===
                                point.getPosition().lat() &&
                            m.marker.getPosition().lng() ===
                                point.getPosition().lng()
                    );
                    console.log('pointsForDeletion', pointsForDeletion);

                    const newMainPolygon = pointsForDeletion[0];
                    const newMainPolygonPaths = [];
                    for (const lPoint of pointsForDeletion) {
                        const paths = lPoint.polygon.polygon
                            .getPath()
                            .getArray();
                        for (const path of paths) {
                            if (
                                path.lat() !== point.getPosition().lat() ||
                                path.lng() !== point.getPosition().lng()
                            ) {
                                newMainPolygonPaths.push(path);
                            }
                        }
                        if (lPoint !== newMainPolygon) {
                            lPoint.polygon.polygon.setMap();
                            lPoint.mainPolygon.children.splice(
                                lPoint.mainPolygon.children.indexOf(
                                    lPoint.polygon
                                ),
                                1
                            );
                        }
                        this.fieldService.markers.splice(
                            this.fieldService.markers.indexOf(lPoint),
                            1
                        );
                        lPoint.marker.setMap(null);
                    }

                    newMainPolygon.polygon.polygon.setPath(
                        this.uniqueLatLng(newMainPolygonPaths)
                    );

                    doIt();
                });
            }
        };
        doIt();

        return false;
    }

    uniqueLatLng(coordinates: google.maps.LatLng[]) {
        coordinates = this.sortCoords(coordinates);
        const uniquePolygonPath = [];
        let lastLat, lastLng;
        for (const path of coordinates) {
            if (path.lat() !== lastLat || path.lng() !== lastLng) {
                lastLat = path.lat();
                lastLng = path.lng();
                uniquePolygonPath.push(path);
            }
        }
        return uniquePolygonPath;
    }

    async splitPolygons(
        polygonData: Polygon,
        firstPosition: google.maps.LatLng,
        secondPosition: google.maps.LatLng,
        mainPolygonData: Polygon
    ) {
        const positions = polygonData.polygon.getPath().getArray();
        let firstPolygonPaths = [firstPosition];
        let secondPolygonPaths = [firstPosition];
        let isFirstPolygon = true;

        for (const position of positions) {
            if (
                (position.lat() == firstPosition.lat() &&
                    position.lng() == firstPosition.lng()) ||
                (position.lat() == secondPosition.lat() &&
                    position.lng() == secondPosition.lng())
            ) {
                isFirstPolygon = !isFirstPolygon;
            }
            (isFirstPolygon ? firstPolygonPaths : secondPolygonPaths).push(
                position
            );
        }

        firstPolygonPaths.push(secondPosition);
        secondPolygonPaths.push(secondPosition);

        firstPolygonPaths = this.uniqueLatLng(firstPolygonPaths);
        secondPolygonPaths = this.uniqueLatLng(secondPolygonPaths);

        // Update first polygon
        polygonData.polygon.setPath(firstPolygonPaths);

        // Create new second polygon
        mainPolygonData.children.push({
            polygon: new google.maps.Polygon({
                paths: secondPolygonPaths,
                map: polygonData.polygon.getMap(),
                strokeColor: '#0000ff',
                strokeOpacity: 0.3,
                strokeWeight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.1,
                zIndex: 2,
                draggable: false,
            }),
        });
    }

    addLine() {
        this.fieldService.createSnapshot();
        const doIt = () => {
            const points = this.startEditing(this.data.selectedPolygon);
            let firstClick: { marker?: Marker } = null;
            for (const pointData of points) {
                const point = pointData.marker;
                google.maps.event.addListener(point, 'click', async () => {
                    if (firstClick) {
                        const firstMarkers = this.fieldService.markers.filter(
                            m =>
                                m.marker &&
                                m.marker.getPosition() &&
                                m.marker.getPosition().lat() ===
                                    point.getPosition().lat() &&
                                m.marker.getPosition().lng() ===
                                    point.getPosition().lng()
                        );
                        const secondMarkers = this.fieldService.markers.filter(
                            m =>
                                m.marker &&
                                m.marker.getPosition() &&
                                m.marker.getPosition().lat() ===
                                    firstClick.marker.getPosition().lat() &&
                                m.marker.getPosition().lng() ===
                                    firstClick.marker.getPosition().lng()
                        );

                        const polygonForSplitting = firstMarkers.filter(m =>
                            secondMarkers.some(
                                s => s.polygon.polygon === m.polygon.polygon
                            )
                        );

                        console.log(
                            'polygonsForSplitting',
                            polygonForSplitting
                        );

                        await this.splitPolygons(
                            polygonForSplitting.map(p => p.polygon)[0],
                            firstClick.marker.getPosition(),
                            point.getPosition(),
                            pointData.mainPolygon
                        );

                        doIt();
                    } else {
                        firstClick = pointData;
                        // Mark marker blue
                        firstClick.marker.setIcon({
                            path: 'M -1 -1 L 1 -1 L 1 1 L -1 1 z',
                            strokeColor: '#FF0000',
                            strokeOpacity: 0,
                            fillColor: '#0000FF',
                            fillOpacity: 1,
                            scale: 5,
                        });
                    }
                });
            }
        };
        doIt();

        return false;
    }

    async mergePolygons(polygons: Polygon[], mainPolygon?: Polygon) {
        if (polygons.length < 2) {
            return;
        }

        console.log(polygons);
        const newMainPolygon = polygons[0];
        const newMainPolygonPaths = [];

        for (const polygonData of polygons) {
            const paths = polygonData.polygon.getPath().getArray();
            for (const path of paths) {
                newMainPolygonPaths.push(path);
            }
            if (polygonData.polygon !== newMainPolygon.polygon) {
                polygonData.polygon.setMap();
                if (mainPolygon) {
                    mainPolygon.children.splice(
                        mainPolygon.children.indexOf(polygonData.polygon),
                        1
                    );
                }
            }
        }

        const newPath = this.sortCoords(newMainPolygonPaths);
        const uniquePolygonPath = [];
        let lastLat, lastLng;
        for (const path of newPath) {
            if (path.lat() !== lastLat || path.lng() !== lastLng) {
                lastLat = path.lat();
                lastLng = path.lng();
                uniquePolygonPath.push(path);
            }
        }
        console.log('newPath', uniquePolygonPath);
        newMainPolygon.polygon.setPath(uniquePolygonPath);
    }

    async deleteLine() {
        this.fieldService.createSnapshot();
        const doIt = () => {
            const points = this.startEditing(this.data.selectedPolygon);
            let firstClick: { marker?: Marker } = null;
            for (const pointData of points) {
                const point = pointData.marker;
                google.maps.event.addListener(point, 'click', async () => {
                    if (firstClick) {
                        const firstMarkers = this.fieldService.markers.filter(
                            m =>
                                m.marker &&
                                m.marker.getPosition() &&
                                m.marker.getPosition().lat() ===
                                    point.getPosition().lat() &&
                                m.marker.getPosition().lng() ===
                                    point.getPosition().lng()
                        );
                        const secondMarkers = this.fieldService.markers.filter(
                            m =>
                                m.marker &&
                                m.marker.getPosition() &&
                                m.marker.getPosition().lat() ===
                                    firstClick.marker.getPosition().lat() &&
                                m.marker.getPosition().lng() ===
                                    firstClick.marker.getPosition().lng()
                        );

                        const polygonsForMerging = firstMarkers.filter(m =>
                            secondMarkers.some(
                                s => s.polygon.polygon === m.polygon.polygon
                            )
                        );

                        await this.mergePolygons(
                            polygonsForMerging.map(p => p.polygon),
                            pointData.mainPolygon
                        );

                        doIt();
                    } else {
                        firstClick = pointData;
                        // Mark marker blue
                        firstClick.marker.setIcon({
                            path: 'M -1 -1 L 1 -1 L 1 1 L -1 1 z',
                            strokeColor: '#FF0000',
                            strokeOpacity: 0,
                            fillColor: '#0000FF',
                            fillOpacity: 1,
                            scale: 5,
                        });
                    }
                });
            }
        };
        doIt();

        return false;
    }

    startEditing(polygon: Polygon) {
        this.fieldService.createSnapshot();
        console.log('Start editing polygon');
        // Clear up
        for (const marker of this.fieldService.markers) {
            if (marker && marker.marker) {
                marker.marker.setMap(null);
            }
        }
        this.fieldService.markers = [];
        console.log('Cleared markers');
        // Create marker points
        for (const subPolygon of polygon.children) {
            const geoCoordinates = subPolygon.polygon.getPath().getArray();
            for (let i = 0; i < geoCoordinates.length; i++) {
                const point = new google.maps.Marker({
                    map: polygon.polygon.map,
                    position: geoCoordinates[i],
                    icon: {
                        path: 'M -1 -1 L 1 -1 L 1 1 L -1 1 z',
                        strokeColor: '#FF0000',
                        strokeOpacity: 0,
                        fillColor: '#FF0000',
                        fillOpacity: 1,
                        scale: 5,
                    },
                });
                this.fieldService.markers.push({
                    marker: point,
                    polygon: subPolygon,
                    mainPolygon: polygon,
                    position: i,
                });
            }
        }
        console.log('Generated markers');

        return this.fieldService.markers;
    }

    sortCoords(coords) {
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < coords.length; i++) {
            bounds.extend(coords[i]);
        }
        // sort coordinates
        const centerPt = bounds.getCenter();
        return coords.sort((a, b) => {
            const bearA = google.maps.geometry.spherical.computeHeading(
                centerPt,
                a
            );
            const bearB = google.maps.geometry.spherical.computeHeading(
                centerPt,
                b
            );
            return bearA - bearB;
        });
    }

    async importDialog() {
        const modal = await this.modalCtrl.create({
            component: DataImportPage,
            backdropDismiss: false,
            componentProps: {},
        });
        modal.onDidDismiss().then(async data => {
            if (data?.data?.length > 0) {
                if (data.data.length > 30) {
                    const alert = await this.alertCtrl.create({
                        header: this.translate.instant('warn'),
                        message: this.translate.instant('moreThan30Fields'),
                        buttons: [
                            {
                                text: this.translate.instant('cancel'),
                                handler: () => {},
                            },
                            {
                                text: this.translate.instant('Ok'),
                                handler: async () => {
                                    const loading =
                                        await this.loadingCtrl.create();
                                    loading.present();
                                    setTimeout(() => {
                                        this.startImport(data.data);
                                        loading.dismiss();
                                    }, 100);
                                },
                            },
                        ],
                    });
                    alert.present();
                    return;
                }
                this.startImport(data.data);
            }
        });

        await modal.present();
    }

    startImport(fields) {
        this.zone.runOutsideAngular(() => {
            for (const field of fields) {
                field.fieldPolygon.setMap(this.map);
                this.bounds.union(
                    this.fieldService.getBounds(field.fieldPolygon)
                );

                this.fieldService.addField(
                    field.fieldPolygon,
                    field.name,
                    field.size,
                    field.note,
                    field.usingCodes,
                    field.additionalFields
                );
            }

            this.map.fitBounds(this.bounds);
            this.map.panToBounds(this.bounds);

            this.chRef.detectChanges();
        });
    }
}
