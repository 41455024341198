import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorWindowComponent } from 'src/components/error-window/error-window.component';
import { HeaderModule } from 'src/components/header/header.module';
import { PrivacyInfoComponent } from 'src/components/privacy-info/privacy-info.component';
import { WizardStepComponentModule } from 'src/components/wizard-step/wizard-step.module';
import { WizardComponentModule } from 'src/components/wizard/wizard.module';
import { StepMapsComponentModule } from './components/steps/step-maps/step-maps.module';
import { StepsModule } from './components/steps/steps.module';
import { HomePage } from './home.page';

const routes: Routes = [
    {
        path: '',
        component: HomePage,
    },
];

@NgModule({
    imports: [
        TranslateModule,
        WizardStepComponentModule,
        StepMapsComponentModule,
        StepsModule,
        WizardComponentModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        HeaderModule,
    ],
    declarations: [HomePage, PrivacyInfoComponent, ErrorWindowComponent],
})
export class HomePageModule {}
