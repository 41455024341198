import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'usingCode',
})
export class UsingCodePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    public transform(value: string | number): string {
        switch (+value) {
            case 183:
                return this.translate.instant('Sorghumhirse');
            case 411:
                return this.translate.instant('Silomais');
            case 422:
                return this.translate.instant('Kleegras');
            case 451:
                return this.translate.instant('Wiesen');
            case 994:
                return this.translate.instant('Lagerung');
            default:
                return value.toString();
        }
    }
}
