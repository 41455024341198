<div class="stepper">
    <a (click)="step('down')">
        <ion-icon name="remove"></ion-icon>
    </a>
    <input
        class="stepper-input"
        min="0"
        [(ngModel)]="value"
        (ngModelChange)="modelChange($event)" />
    <a (click)="step('up')">
        <ion-icon name="add"></ion-icon>
    </a>
</div>
