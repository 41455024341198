import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageModule } from 'src/pages/home/home.module';
import { ThankYouPageModule } from 'src/pages/thank-you/thank-you.module';
import { OldBrowserPageModule } from 'src/pages/old-browser/old-browser.module';

const routes: Routes = [
    { path: '', loadChildren: () => HomePageModule },
    { path: 'thank-you', loadChildren: () => ThankYouPageModule },
    { path: 'old-browser', loadChildren: () => OldBrowserPageModule },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
