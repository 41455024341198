import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { DataImportPageModule } from 'src/components/data-import/data-import.module';
import { FieldListComponentModule } from 'src/components/field-list/field-list.module';
import { StepperComponentModule } from 'src/components/stepper/stepper.module';
import { PipesModule } from 'src/pipes/pipes.module';
import { HelpModal } from './modal/help.modal';
import { StepMapsComponent } from './step-maps';

@NgModule({
    declarations: [StepMapsComponent, HelpModal],
    imports: [
        PipesModule,
        TranslateModule.forChild(),
        FieldListComponentModule,
        IonicModule,
        CommonModule,
        FormsModule,
        StepperComponentModule,
        DataImportPageModule,
        TippyModule,
    ],
    exports: [StepMapsComponent, HelpModal],
})
export class StepMapsComponentModule {}
