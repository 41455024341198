import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class PortalInterceptor implements HttpInterceptor {
    constructor(private translate: TranslateService) {}

    isIE() {
        const userAgent = navigator.userAgent;
        return (
            userAgent.indexOf('MSIE ') > -1 ||
            userAgent.indexOf('Trident/') > -1 ||
            userAgent.indexOf('Edge/') > -1
        );
    }

    getPortalHostname(): string {
        let loc;
        if (this.isIE()) {
            loc = document.location.href.toLowerCase();
        } else {
            loc = (
                window.location !== window.parent.location
                    ? document.referrer
                    : document.location.href
            ).toLowerCase();
        }

        const urlSearchParams = new URL(location.href).searchParams;
        if (urlSearchParams.has('portal')) {
            return urlSearchParams.get('portal');
        }

        const parser: any = document.createElement('a');
        parser.href = loc;
        const hostname = parser.hostname.replace(/^www\./, '');
        console.log('Finding portal for', parser.href, hostname);

        if (hostname.includes('mr-geo.com')) {
            return 'cp.mr-geo.com';
        } else if (hostname.includes('biocare.de')) {
            return 'cloud.biocare.de';
        } else if (hostname.includes('order.webaro.dev')) {
            return 'webaro.dev';
        } else if (hostname.includes('test.order')) {
            return 'webaro.dev';
        } else if (
            hostname.includes('maschinenring.de') &&
            parser.href.match(/coburg-kronach-lichtenfels/)
        ) {
            return 'cp-mr-co-kc-lif.de';
        } else if (hostname.includes('maschinenring.de')) {
            return 'cp-mr-co-kc-lif.de';
            // return 'mrd.webaro.cloud';
        } else if (hostname.includes('bayern-drohne.de')) {
            return 'cp.bayern-drohne.de';
        } else if (hostname.includes('trichonline.ch')) {
            return 'cp.trichonline.ch';
        } else if (hostname.includes('aerial-solutions.de')) {
            this.translate.use('aerial-solutions');
            return 'cp.aerial-solutions.de';
        } else if (hostname.includes('baywa.rangerotors.de')) {
            return 'baywa-wuerttemberg.webaro.cloud';
        } else if (hostname === 'farmfacts.de') {
            return 'drohne.farmfacts.de';
        } else if (hostname === 'luftbilder-thueringen.net') {
            return 'cp.luftbilder-thueringen.net';
        } else if (hostname === 'agrar-drohnenservice.de') {
            return 'cp.agrar-drohnenservice.de';
        } else if (hostname === 'tricho-flight.at') {
            return 'cp.tricho-flight.at';
        } else if (hostname.includes('mr-bamberg.rangerotors.de')) {
            return 'mr-bamberg.webaro.cloud';
        } else if (hostname.includes('mr-trier-wittlich.rangerotors.de')) {
            return 'mr-trier-wittlich.webaro.cloud';
        } else if (
            hostname.includes('rangerotors.de') ||
            hostname.includes('order.rangerotors.de')
        ) {
            return 'webaro.cloud';
        } else if (
            hostname.includes('webaro.de') ||
            hostname.includes('order.webaro.cloud')
        ) {
            return 'webaro.cloud';
        } else if (
            hostname.includes('localhost') ||
            hostname.includes('127.0.0.1')
        ) {
            return 'webaro.dev';
        }
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const portal = this.getPortalHostname();
        let portalReq;
        if (portal) {
            portalReq = request.clone({
                headers: request.headers.set('Portal', portal),
            });
        } else {
            portalReq = request;
        }

        return next.handle(portalReq);
    }
}
