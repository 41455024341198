import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ServiceOrganisationComponent } from 'src/components/service-organisation/service-organisation.component';
import { PortalService } from 'src/models/portal-service.model';
import { ServiceOptionsComponent } from '../../../../../../../components/service-options/service-options.component';
import { DataService } from '../../../../../../../services/data.service';

@Component({
    selector: 'image-box',
    templateUrl: 'image-box.html',
    styleUrls: ['./image-box.scss'],
})
export class ImageBoxComponent {
    @Input() itemsPerRow;
    @Input() portalService: PortalService;

    constructor(
        public dataService: DataService,
        private modalCtrl: ModalController
    ) {}

    async serviceOption() {
        return new Promise(async resolve => {
            if (
                this.portalService.options?.length ||
                this.portalService.planned_date_enabled
            ) {
                const modal = await this.modalCtrl.create({
                    component: ServiceOptionsComponent,
                    componentProps: {
                        data: this.portalService,
                        portalService: this.portalService,
                    },
                });
                modal.onDidDismiss().then(async () => {
                    resolve(true);
                });
                await modal.present();
            }
        });
    }

    async serviceOrganisation() {
        return new Promise(async resolve => {
            if (this.portalService.portal_service_organizations?.length) {
                if (
                    this.portalService.portal_service_organizations?.length == 1
                ) {
                    this.portalService.portalServiceOrganizationTitle =
                        this.portalService.portal_service_organizations[0].title;
                    this.portalService.portalServiceOrganization = this
                        .portalService.portal_service_organizations[0]
                        .id as string;
                    resolve(true);
                } else {
                    const modal = await this.modalCtrl.create({
                        component: ServiceOrganisationComponent,
                        componentProps: {
                            data: this.portalService,
                            portalService: this.portalService,
                        },
                    });
                    modal.onDidDismiss().then(async () => {
                        resolve(true);
                    });
                    await modal.present();
                }
            }
        });
    }

    description() {
        this.dataService.showAlert(
            this.portalService.title || this.portalService.service.title,
            this.portalService.description ||
                this.portalService.service.description,
            [],
            'break-word'
        );
    }

    async activate(portalService, checked: boolean) {
        if (!this.dataService.configuration?.portal?.allow_multiple_services) {
            this.dataService.configuration?.portal?.portal_services.forEach(
                ps => (ps.isChecked = false)
            );
        }

        if (checked) {
            if (
                this.portalService.options?.length ||
                this.portalService.planned_date_enabled
            ) {
                await this.serviceOption();
            }
            if (this.portalService.portal_service_organizations?.length) {
                await this.serviceOrganisation();
            }
        }

        portalService.isChecked = checked;
        this.dataService.orderDataServices = [];
        this.dataService.configuration?.portal?.portal_services.forEach(
            (ps: PortalService) => {
                if (ps.isChecked) {
                    this.dataService.orderDataServices.push(ps);
                }
            }
        );

        /*const active = this.active();
    if (!this.data.service.title) {
      this.data.service.title = this.data.title;
    }
    this.dataService.orderData.service = active ? null : this.data.service;
    this.dataService.orderData.portalService = active ? null : this.data.id;*/
    }

    active() {
        return this.dataService &&
            this.dataService.orderData &&
            this.dataService.orderData.service &&
            this.dataService.orderData.portalService == this.portalService.id
            ? true
            : false;
    }
}
