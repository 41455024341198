import { Component, ChangeDetectorRef, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FieldService } from 'src/pages/home/components/steps/step-maps/services/field.service';
import { Polygon } from 'src/models/polygon.model';

@Component({
    selector: 'field-list',
    templateUrl: 'field-list.html',
    styleUrls: ['./field-list.scss'],
})
export class FieldListComponent {
    @Input() map;
    active: Polygon;

    constructor(
        public dataService: DataService,
        public alertCtrl: AlertController,
        public translate: TranslateService,
        private chRef: ChangeDetectorRef,
        public fieldService: FieldService
    ) {}

    deleteField(polygonId: string) {
        this.fieldService.markers.forEach(marker => {
            marker.marker.setMap(null);
        });

        for (const field of this.dataService.orderData.polygons) {
            if (field.polygonId == polygonId) {
                if (field.children) {
                    field.children.forEach(subPolygon => {
                        subPolygon.polygon.setMap(null);
                    });
                }
                field.polygon.setMap(null);
                this.dataService.orderData.polygons =
                    this.dataService.orderData.polygons.filter(value => {
                        return value.polygonId !== polygonId;
                    });
                this.chRef.detectChanges();
            }
        }

        this.dataService.gridCalculator = false;
        this.chRef.detectChanges();
    }

    async editField(data: Polygon) {
        this.active = data;
        try {
            await this.fieldService.editField(data, false, this.map);
        } catch (e) {
        } finally {
            delete this.active;
        }
    }

    public get totalSize(): number {
        return this.dataService.orderData.polygons.reduce(
            (acc, cur) => acc + +cur.size,
            0
        );
    }

    public get totalSizeCalculated(): number {
        return this.dataService.orderData.polygons.reduce(
            (acc, cur) => acc + +cur.sizeCalculated,
            0
        );
    }
}
