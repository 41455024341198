import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/services/data.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-field-edit',
    templateUrl: './field-edit.component.html',
    styleUrls: ['./field-edit.component.scss'],
})
export class FieldEditComponent implements OnInit {
    @Input() header: string;
    @Input() message: string;
    @Input() orderData: any;
    @Input() polygon: any;
    inputs = [];
    additionalFieldData = [];

    constructor(
        private modalCtrl: ModalController,
        private translate: TranslateService,
        private toast: HotToastService,
        private dataService: DataService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.inputs.push({
            name: 'label',
            placeholder:
                this.orderData.service &&
                this.orderData.service.translations &&
                this.orderData.service.translations.fields
                    ? this.translate.instant('Name of the object')
                    : this.translate.instant('Name of the field'),
            value: this.polygon.label,
            type: 'text',
        });

        if (
            !this.orderData.service.configuration ||
            !this.orderData.service.configuration.hectare_disabled
        ) {
            this.inputs.push({
                name: 'size',
                placeholder: this.translate.instant('Size of the field in HA'),
                min: 0,
                type: 'number',
                value: this.polygon.size,
            });
        }
        this.inputs.push({
            name: 'note',
            placeholder: this.translate.instant('Note'),
            type: 'text',
            value: this.polygon.note,
        });

        if (this.dataService.configuration.portal?.additional_fields) {
            try {
                this.additionalFieldData = JSON.parse(
                    JSON.stringify(
                        this.dataService.configuration.portal?.additional_fields
                    )
                );
                this.additionalFieldData.forEach(aF => {
                    const found = this.polygon?.additionalFields?.find(
                        o => o.id === aF.id
                    );
                    if (found) {
                        aF.value = found.value;
                        if (aF.dataType === 'checkbox') {
                            aF.choices.forEach(
                                c =>
                                    (c.checked =
                                        found.value?.indexOf(c.title) !== -1)
                            );
                        }
                    }
                });
            } catch (error) {}
        }
    }

    cancel() {
        this.modalCtrl.dismiss({ dismiss: true });
    }

    save() {
        console.log('save', this.inputs, this.additionalFieldData);
        let polygon = this.polygon;
        let label = this.inputs.find(input => input.name == 'label').value;
        let size = this.inputs.find(input => input.name == 'size').value;
        let note = this.inputs.find(input => input.name == 'note').value;
        if (
            !this.orderData.service.configuration ||
            !this.orderData.service.configuration.hectare_disabled
        ) {
            if (!label?.length || !size?.length) {
                this.toast.warning(this.translate.instant('data incomplete'));
                return false;
            }
        } else {
            if (!label?.length) {
                this.toast.warning(this.translate.instant('data incomplete'));
                return false;
            }
        }
        polygon.label = label;
        polygon.size = size;
        polygon.sizeCalculated = +(
            google.maps.geometry.spherical.computeArea(
                typeof polygon.polygon == 'object'
                    ? polygon.polygon.getPath()
                    : polygon.getPath()
            ) / 10000
        ).toFixed(2);

        polygon.note = note;

        polygon.additionalFields =
            this.dataService.configuration.portal?.additional_fields?.map(o => {
                const found = this.additionalFieldData.find(
                    aF => aF.id === o.id
                );
                let val = found.value;
                if (o.dataType === 'checkbox') {
                    val = found.choices
                        .filter(c => c.checked)
                        .map(c => c.title);
                }
                return {
                    id: o.id,
                    title: o.title,
                    value: val ?? null,
                };
            });

        if (this.relDiff(polygon.size, polygon.sizeCalculated) > 20) {
            Swal.fire({
                title: this.translate.instant('Info'),
                text: this.translate.instant('deviation20More'),
                icon: 'info',
                showCancelButton: false,
            });
        }
        console.log('Data', polygon);
        this.cdr.detectChanges();
        this.modalCtrl.dismiss(polygon);
    }

    relDiff(a, b) {
        return Math.abs((1 - a / b) * 100);
        //return 100 * Math.abs((a - b) / ((a + b) / 2));
    }
}
