import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { WizardComponent } from './wizard';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [WizardComponent],
    imports: [TranslateModule, IonicModule, CommonModule, FormsModule],
    exports: [WizardComponent],
})
export class WizardComponentModule {}
