<ion-app>
    <button
        (click)="openLanguage()"
        class="language-switcher">
        <img
            *ngIf="language.startsWith('de')"
            src="./assets/icons/DE.png" />
        <img
            *ngIf="language.startsWith('en')"
            src="./assets/icons/US.png" />
        <img
            *ngIf="language.startsWith('fr')"
            src="./assets/icons/FR.png" />
    </button>
    <ion-router-outlet></ion-router-outlet>
    <app-footer></app-footer>
</ion-app>
