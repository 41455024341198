import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceOrganisationComponentModule } from 'src/components/service-organisation/service-organisation.module';
import { ServiceOptionsComponentModule } from '../../../../../../../components/service-options/service-options.module';
import { ImageBoxComponent } from './image-box';

@NgModule({
    declarations: [ImageBoxComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        CommonModule,
        ServiceOptionsComponentModule,
        ServiceOrganisationComponentModule,
        TranslateModule,
        TippyModule,
    ],
    exports: [ImageBoxComponent],
})
export class ImageBoxComponentModule {}
