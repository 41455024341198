<ion-content>
    <!--<div class="background-image"></div>-->
    <div class="center-content">
        <div class="white-container">
            <h1>{{ 'Outdated browser detected' | translate }}</h1>
            <p>{{ 'browser outdated text' | translate }}</p>

            <div class="browser-list">
                <a
                    href="https://www.google.com/chrome/"
                    target="_blank">
                    <div class="browser-image chrome-image"></div>
                    <span>Google Chrome</span>
                </a>
                <a
                    href="https://www.mozilla.org/de/firefox/new/"
                    target="_blank">
                    <div class="browser-image firefox-image"></div>
                    <span>Mozilla Firefox</span>
                </a>
                <a
                    href="https://www.opera.com/de"
                    target="_blank">
                    <div class="browser-image opera-image"></div>
                    <span>Opera</span>
                </a>
            </div>
        </div>
    </div>
</ion-content>
