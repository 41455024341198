<!--
   [class.disabled]="!active() && dataService.orderData?.service?.title"
   [class.active]="active()"
-->
<div
    class="image-box"
    [class.active]="portalService.isChecked"
    [class.items-per-row-1]="itemsPerRow == '1'"
    [class.items-per-row-2]="itemsPerRow == '2'"
    [class.items-per-row-3]="itemsPerRow == '3'">
    <div
        *ngIf="portalService.description || portalService.service.description"
        class="information-button">
        <ion-button
            [tippy]="'Description' | translate"
            fill="clear"
            size="small"
            color="light"
            (click)="description()"
            color="light">
            <ion-icon name="information-circle"></ion-icon>
        </ion-button>
    </div>
    <div
        *ngIf="portalService.image"
        class="background"
        [ngStyle]="{ 'background-image': 'url(' + portalService.image + ')' }"></div>

    <div
        class="center"
        (click)="activate(portalService, !portalService.isChecked)">
        <h2>{{ portalService.title || portalService.service.title }}</h2>
    </div>

    <div class="gradient">
        <ion-button
            *ngIf="
        portalService.portal_service_organizations?.length > 1 &&
        portalService.isChecked
      "
            [tippy]="'Organisation' | translate"
            fill="clear"
            size="small"
            color="light"
            (click)="serviceOrganisation()"
            ><ion-icon name="document-text-outline"></ion-icon>
        </ion-button>
        <ion-button
            *ngIf="portalService.options?.length && portalService.isChecked"
            [tippy]="'Service Options' | translate"
            fill="clear"
            size="small"
            color="light"
            (click)="serviceOption()"
            ><ion-icon name="apps-outline"></ion-icon>
        </ion-button>
        <ion-checkbox
            [tippy]="'Select' | translate"
            [(ngModel)]="portalService.isChecked"
            (ngModelChange)="activate(portalService, $event)"></ion-checkbox>
    </div>
</div>
