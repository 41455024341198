import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Polygon } from 'src/models/polygon.model';
import { DataService } from 'src/services/data.service';
import { StartingCorner } from 'src/services/startingcorner.enum';
import { GridSubpolygonCalculatorResponse } from './grid-subpolygon-calculator-response.model';

@Injectable({ providedIn: 'root' })
export class FieldService {
    markers: {
        marker: google.maps.Marker;
        polygon: Polygon;
        mainPolygon: Polygon;
        position: number;
    }[] = [];
    public gridSize: any = 0;
    public offsetX = 0;
    public offsetY = 0;
    public degree = 0;

    constructor(
        public dataService: DataService,
        public http: HttpClient,
        public loadingCtrl: LoadingController
    ) {}

    async addField(
        polygon: any,
        label: string = null,
        size: string | number = null,
        note: string = '',
        usingCodes?: string[],
        additionalFields?: any[]
    ) {
        const polygonId = this.dataService.lastPolygonNo++;
        const fieldSize = (
            google.maps.geometry.spherical.computeArea(
                typeof polygon.polygon == 'object'
                    ? polygon.polygon.getPath()
                    : polygon.getPath()
            ) / 10000
        ).toFixed(2);
        // let states: string[] = [];
        // try {
        //     states = await this.getStatesForPolygon(polygon);
        // } catch (e) {
        //     console.log('error while getting states for polygon', polygon, e);
        // }

        const fieldData: Polygon = {
            polygonId,
            polygon,
            label,
            size,
            note,
            gPaths: polygon.getPath().getArray(),
            sizeCalculated: fieldSize,
            usingCodes,
            additionalFields
        };

        if (
            this.dataService.orderData.service.id ===
            '30303030-3030-3030-3030-303030303132'
        ) {
            const loading = await this.loadingCtrl.create();
            loading.present();
            fieldData.polygon.setEditable(false);
            await this.editField(fieldData);
            loading.dismiss();
        }

        this.dataService.orderData.polygons.push(fieldData);

        const calcGroundBuffer = () => {
            /*let groundBufferPaths = OffsetPolygonCalculator.compute(typeof polygon.polygon == 'object'
        ? polygon.polygon.getPath().getArray()
        : polygon.getPath().getArray(), typeof polygon.polygon == 'object'
        ? polygon.polygon.getMap()
        : polygon.getMap(), 5);

      if (fieldData.groundBufferWarnPolygon) {
        fieldData.groundBufferWarnPolygon.setMap(null);
      }

      fieldData.groundBufferWarnPolygon = new google.maps.Polygon({
        paths: groundBufferPaths,
        draggable: false,
        fillColor: '#DC3B3B',
        fillOpacity: 0.6,
        strokeColor: '#DC3B3B',
        strokeWeight: 3,
        map: typeof polygon.polygon == 'object'
          ? polygon.polygon.getMap()
          : polygon.getMap()
      });

      groundBufferPaths = OffsetPolygonCalculator.compute(typeof polygon.polygon == 'object'
        ? polygon.polygon.getPath().getArray()
        : polygon.getPath().getArray(), typeof polygon.polygon == 'object'
        ? polygon.polygon.getMap()
        : polygon.getMap(), 10);

      if (fieldData.groundBufferAlertPolygon) {
        fieldData.groundBufferAlertPolygon.setMap(null);
      }

      fieldData.groundBufferAlertPolygon = new google.maps.Polygon({
        paths: groundBufferPaths,
        draggable: false,
        fillColor: '#DC3B3B',
        fillOpacity: 0.6,
        strokeColor: '#DC3B3B',
        strokeWeight: 3,
        map: typeof polygon.polygon == 'object'
          ? polygon.polygon.getMap()
          : polygon.getMap()
      });*/
        };

        const somethingChanged = () => {
            console.log('somethingChanged', polygon);
            fieldData.sizeCalculated = (
                google.maps.geometry.spherical.computeArea(
                    typeof polygon.polygon == 'object'
                        ? polygon.polygon.getPath()
                        : polygon.getPath()
                ) / 10000
            ).toFixed(2);
            fieldData.gPaths =
                typeof polygon.polygon == 'object'
                    ? polygon.polygon.getPath()
                    : polygon.getPath().getArray();
            calcGroundBuffer();
        };
        calcGroundBuffer();

        google.maps.event.addListener(
            polygon.getPath(),
            'insert_at',
            somethingChanged
        );
        google.maps.event.addListener(
            polygon.getPath(),
            'set_at',
            somethingChanged
        );

        // this.loadPolygons(map);
        this.dataService.selectedPolygon =
            this.dataService.orderData.polygons[
                this.dataService.orderData.polygons.length - 1
            ];
        setTimeout(() => {
            this.dataService.events.publish('maps:cdr');
        }, 50);
    }

    async editField(polyData, doSplit: boolean = true, map?) {
        // Enable Grid Calculator only for Bodenproben
        if (
            this.dataService.orderData.service.id ===
            '30303030-3030-3030-3030-303030303132'
        ) {
            this.dataService.gridCalculator = true;
            this.dataService.selectedPolygon = polyData;

            if (!polyData.childrenOptions) {
                let degree: number;
                try {
                    degree = Math.round(
                        await this.calculateDegreeOfLongestSide(
                            polyData.polygon.getPath().getArray()
                        )
                    );
                } catch (e) {
                    degree = 0;
                }
                polyData.childrenOptions = {
                    gridSize: 0,
                    offsetX: 0,
                    offsetY: 0,
                    degree,
                };
            }

            this.gridSize = polyData.childrenOptions.gridSize;
            this.offsetX = polyData.childrenOptions.offsetX;
            this.offsetY = polyData.childrenOptions.offsetY;
            this.degree = polyData.childrenOptions.degree;

            if (doSplit) {
                await this.splitField(polyData);
            }

            if (map) {
                const bounds = new google.maps.LatLngBounds();
                bounds.union(this.getBounds(polyData.polygon));
                map.fitBounds(bounds);
                map.panToBounds(bounds);
            }
        } else {
            this.dataService.gridCalculator = false;
            // Edit the Name of the Field (Old Way)
            await this.dataService.editPolygonField(polyData, 'edit');
        }
    }

    getBounds(polygon: google.maps.Polygon) {
        const _bounds = new google.maps.LatLngBounds();
        polygon.getPath().forEach(element => {
            _bounds.extend(element);
        });
        return _bounds;
    }

    // Currently not used
    loadPolygons(map) {
        const polygons = this.dataService.orderData.polygons;
        /** Load polygons */
        const bounds = new google.maps.LatLngBounds();
        for (const polygon of polygons) {
            /** Add to map */
            polygon.polygonId = this.dataService.lastPolygonNo++;

            polygon.polygon = new google.maps.Polygon({
                paths: polygon.gPaths,
                editable: false,
                draggable: false,
                fillColor: '#fff',
                fillOpacity: 0.2,
                strokeColor: '#DC3B3B',
                strokeWeight: 3,
            });
            polygon.polygon.setMap(map);
            bounds.union(polygon.polygon.getBounds());
        }

        // Add to last Polygon for Grid Calculator
        this.dataService.selectedPolygon = polygons[polygons.length - 1];
    }

    async splitField(polyData: Polygon = this.dataService.selectedPolygon) {
        // Delete old children if there are some
        if (typeof polyData.children === 'object') {
            for (const children of polyData.children) {
                children.polygon.setMap(null);
            }
        }

        if (polyData.childrenOptions.gridSize < 1) {
            polyData.children = [];
            return;
        }

        // Generate all children polygons
        const coordinates = polyData.polygon.getPath().getArray();
        const gridSubpolygons = await this.http
            .post<GridSubpolygonCalculatorResponse[]>(
                this.dataService.configuration.portal_url +
                    '/api/calculate/gridSubpolygons',
                {
                    paths: coordinates.map(coordinate => ({
                        latitude: coordinate.lat(),
                        longitude: coordinate.lng(),
                    })),
                    gridSizeX: polyData.childrenOptions.gridSize,
                    gridSizeY: polyData.childrenOptions.gridSize,
                    offsetX: polyData.childrenOptions.offsetX,
                    offsetY: polyData.childrenOptions.offsetY,
                    degree: polyData.childrenOptions.degree,
                    startingCorner: StartingCorner.TOP_LEFT,
                }
            )
            .toPromise();

        // Save children
        polyData.children = gridSubpolygons.map(_polygon => ({
            polygon: new google.maps.Polygon({
                paths: _polygon.geoCoordinates.map(
                    _coordinates =>
                        new google.maps.LatLng(
                            _coordinates.latitude,
                            _coordinates.longitude
                        )
                ),
                map: polyData.polygon.getMap(),
                strokeColor: '#0000ff',
                strokeOpacity: 0.3,
                strokeWeight: 2,
                fillColor: '#ffffff',
                fillOpacity: 0.1,
                zIndex: 2,
                draggable: false,
            }),
        }));
    }

    calculateDegreeOfLongestSide(
        coordinates: google.maps.LatLng[]
    ): Promise<number> {
        return this.http
            .post<number>(
                this.dataService.configuration.portal_url +
                    '/api/calculate/degreeOfLongestSide',
                {
                    paths: coordinates.map(coordinate => ({
                        latitude: coordinate.lat(),
                        longitude: coordinate.lng(),
                    })),
                }
            )
            .toPromise();
    }

    createSnapshot() {
        console.log('Create Snapshot', this.dataService.selectedPolygon);
        if (this.dataService.selectedPolygon) {
            const selectedPolygonCopy = Object.assign(
                {},
                this.dataService.selectedPolygon
            );
            this.dataService.historyArray.push(selectedPolygonCopy);
        }

        console.log(this.dataService.historyArray);
    }

    deleteFields() {
        this.markers.forEach(marker => {
            marker.marker.setMap(null);
        });

        for (const field of this.dataService.orderData.polygons) {
            if (field.children) {
                field.children.forEach(subPolygon => {
                    subPolygon.polygon.setMap(null);
                });
            }
            field.polygon.setMap(null);
        }
    }

    async getStatesForPolygon(polygon: google.maps.Polygon): Promise<string[]> {
        const geocoder = new google.maps.Geocoder();
        const states: string[] = [];

        for (const point of polygon.getPath().getArray()) {
            const result = await this.geocodePoint(geocoder, point);
            if (result && result.length) {
                let state = null;
                resultLoop: for (let i = result.length - 1; i >= 0; i--) {
                    if (result[i].address_components?.length) {
                        for (const addressComponent of result[i]
                            ?.address_components) {
                            if (
                                addressComponent.types.includes(
                                    'administrative_area_level_1'
                                )
                            ) {
                                state = addressComponent.long_name;
                                break resultLoop;
                            }
                        }
                    }
                }
                if (state) {
                    states.push(state);
                }
            }
        }

        return Array.from(new Set(states));
    }

    private geocodePoint(
        geocoder: google.maps.Geocoder,
        point: google.maps.LatLng
    ): Promise<google.maps.GeocoderResult[]> {
        return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
            geocoder.geocode(
                { location: point, language: 'en', region: 'DE' },
                (results, status) => {
                    if (status === 'OK') {
                        resolve(results);
                    } else {
                        reject(status);
                    }
                }
            );
        });
    }
}
