import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WizardStepComponentType } from 'src/common/interfaces';

@Component({
    selector: 'wizard-step',
    templateUrl: './wizard-step.html',
})
export class WizardStepComponent {
    @Input() title: string;
    @Input() component: WizardStepComponentType;

    @Input() hidden = false;
    @Input() isValid = true;
    @Input() showNext = true;
    @Input() showPrev = true;

    @Output() doNext: EventEmitter<any> = new EventEmitter<any>();
    @Output() doPrev: EventEmitter<any> = new EventEmitter<any>();
    @Output() doComplete: EventEmitter<any> = new EventEmitter<any>();

    private _isActive = false;
    isDisabled = true;

    constructor() {}

    @Input('isActive') set isActive(isActive: boolean) {
        this._isActive = isActive;
        this.isDisabled = false;
    }

    get isActive(): boolean {
        return this._isActive;
    }
}
