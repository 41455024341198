import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceOptionsTableComponent } from './components/service-options-table/service-options-table.component';
import { ServiceOptionsComponent } from './service-options.component';

@NgModule({
    declarations: [ServiceOptionsComponent, ServiceOptionsTableComponent],
    imports: [
        IonicModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
    ],
    exports: [ServiceOptionsComponent, ServiceOptionsTableComponent],
})
export class ServiceOptionsComponentModule {}
