import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/services/data.service';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { PortalInterceptor } from '../../services/interceptors/portal.interceptor';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.page.html',
    styleUrls: ['./thank-you.page.scss'],
})
export class ThankYouPage implements OnInit {
    bookingUrl;

    constructor(
        private dataService: DataService,
        public navCtrl: NavController,
        public route: ActivatedRoute,
        private portalInterceptor: PortalInterceptor
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(data => {
            if (data) {
                this.bookingUrl = decodeURI(data.bookingUrl);
            }
        });
    }

    async goToHome() {
        const urlSearchParams = new URL(location.href).searchParams;
        const queryParams: any = {};
        if (urlSearchParams.has('portal')) {
            queryParams.portal = urlSearchParams.get('portal');
        } else {
            queryParams.portal = this.portalInterceptor.getPortalHostname();
        }
        if (urlSearchParams.has('partner')) {
            queryParams.partner = urlSearchParams.get('partner');
        }

        await this.navCtrl.navigateRoot('', {
            queryParams,
        });
        setTimeout(async () => {
            await this.dataService.reset();
        }, 100);
    }

    goToOrder() {
        window.open(this.bookingUrl);
    }
}
