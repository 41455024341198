<div class="field-list">
    <div
        class="list-item"
        *ngIf="dataService.orderData?.polygons?.length">
        <div class="grid">
            <p>
                <strong>{{'Total area'|translate}}</strong> {{ totalSize |
                number: '1.2-2' }}ha
            </p>
            <p>
                <strong>{{'Calculated'|translate}}</strong> {{
                totalSizeCalculated | number: '1.2-2' }}ha
            </p>

            <p>
                <strong>Felder:</strong>
                {{dataService?.orderData?.polygons?.length || 0}}
            </p>
        </div>
    </div>
    <div
        class="list-item"
        *ngFor="let field of dataService?.orderData?.polygons"
        [ngStyle]="{ 'background-color': field == active ? '#77cfda' : 'white' }">
        <div class="grid">
            <h3>{{ field.label }}</h3>
            <p [hidden]="!field.size">{{ field.size }}ha</p>
            <p [hidden]="!field.sizeCalculated">
                {{ 'Calculated' | translate }}: {{ field.sizeCalculated }}ha
            </p>
            <p *ngIf="field.note">
                <strong>{{ 'Note' | translate }}:</strong> {{ field.note }}
            </p>
        </div>

        <div class="right">
            <ion-button
                [tippy]="'edit'|translate"
                slot="end"
                size="small"
                fill="clear"
                color="dark"
                (click)="editField(field)">
                <ion-icon name="create-outline"></ion-icon>
            </ion-button>
            <ion-button
                [tippy]="'delete'|translate"
                slot="end"
                size="small"
                fill="clear"
                color="dark"
                (click)="deleteField(field?.polygonId)">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </div>
    </div>
    <div
        class="list-item"
        *ngIf="!dataService?.orderData?.polygons?.length">
        {{ 'no_fields' | translate }}
    </div>
</div>
