import { Component, Input, OnInit } from '@angular/core';
import { PortalServiceOption } from 'src/models/portal-service-option.model';
import { PortalService } from 'src/models/portal-service.model';
import { DataService } from 'src/services/data.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-service-options-table',
    templateUrl: './service-options-table.component.html',
    styleUrls: ['./service-options-table.component.scss'],
})
export class ServiceOptionsTableComponent implements OnInit {
    @Input() checkbox = true;
    @Input() portalService: PortalService;
    environment = environment;
    selectedOption: string = '';

    constructor(private dataService: DataService) {}

    ngOnInit() {
        if (
            this.portalService.options.length &&
            environment.biocare &&
            this.checkbox === true
        ) {
            this.selectedOption = this.portalService.options[0].id;
            this.portalService.options.forEach(option => {
                option.isChecked = false;
            });
            this.portalService.options[0].isChecked = true;
            this.dataService.orderData.portalServiceOptions = [];
            this.dataService.orderData.portalServiceOptions.push(
                this.portalService.options[0]
            );
        }
    }

    async updateColumns() {
        this.dataService.orderData.portalServiceOptions = [];
        this.portalService.options.forEach((option: PortalServiceOption) => {
            if (option.isChecked) {
                this.dataService.orderData.portalServiceOptions.push(option);
            }
        });
    }

    onSelectOption() {
        this.dataService.orderData.portalServiceOptions = [];
        this.portalService.options.forEach(option => {
            option.isChecked = false;
        });
        let selected = this.portalService.options.find(
            option => option.id === this.selectedOption
        );
        selected.isChecked = true;

        this.dataService.orderData.portalServiceOptions.push(selected);
    }
}
