import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { FieldListComponent } from './field-list';

@NgModule({
    declarations: [FieldListComponent],
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule.forChild(),
        TippyModule,
    ],
    exports: [FieldListComponent],
})
export class FieldListComponentModule {}
