import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TippyModule } from '@ngneat/helipopper';
import { TranslateModule } from '@ngx-translate/core';
import { FieldEditComponent } from './field-edit.component';

@NgModule({
    declarations: [FieldEditComponent],
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule.forChild(),
        TippyModule,
        FormsModule,
    ],
    exports: [FieldEditComponent],
})
export class FieldEditComponentModule {}
