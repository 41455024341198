import { NgModule } from '@angular/core';
import { WizardStepComponent } from './wizard-step';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [WizardStepComponent],
    imports: [IonicModule, CommonModule],
    exports: [WizardStepComponent],
})
export class WizardStepComponentModule {}
