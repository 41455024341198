<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{ 'Legal' | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding p-3">
    <ion-segment
        mode="ios"
        [value]="mode"
        (ionChange)="segmentChanged($event)">
        <ion-segment-button
            mode="ios"
            value="agbs">
            {{ 'Terms of Service' | translate }}
        </ion-segment-button>
        <ion-segment-button
            mode="ios"
            value="privacy">
            {{ 'Privacy policy' | translate }}
        </ion-segment-button>
        <ion-segment-button
            mode="ios"
            value="imprint">
            {{ 'Imprint' | translate }}
        </ion-segment-button>
    </ion-segment>
    <p [innerHTML]="text"></p>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                {{ 'Close' | translate }}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
