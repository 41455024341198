import MapOptions = google.maps.MapOptions;
import ControlPosition = google.maps.ControlPosition;

export class MapConfig {
    public static center: google.maps.LatLngLiteral | google.maps.LatLng = {
        lat: 50.1605851,
        lng: 11.1691367,
    };

    public static config: MapOptions = {
        center: MapConfig.center,
        zoom: 14,
        tilt: 0,
        disableDefaultUI: true,
        rotateControl: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.TOP_CENTER,
        },
        zoomControlOptions: {
            position: ControlPosition.TOP_CENTER,
        },
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        zoomControl: true,
        fullscreenControl: false,
        gestureHandling: 'cooperative',
    };
}
