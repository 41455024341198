import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    LoadingController,
    ModalController,
    NavController,
    ToastController,
} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { HotToastService } from '@ngneat/hot-toast';
import { EventsService } from 'src/services/event.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
})
export class HomePage implements AfterViewInit {
    data: any;
    mapsVisible = false;
    slideOneForm: any;

    submitAttempt;
    @ViewChild('contentElm', { static: true }) content: any;

    @ViewChild('formWizard') set formWizard(element) {
        if (element) {
            this.dataService.currentHelpText =
                this.dataService?.configuration?.portal?.order_steps[0].help_text;
        }
    }

    constructor(
        public dataService: DataService,
        public storage: Storage,
        public http: HttpClient,
        private toast: HotToastService,
        public navCtrl: NavController,
        public loadingCtrl: LoadingController,
        public activeRoute: ActivatedRoute,
        public router: Router,
        private events: EventsService,
        private modalCtrl: ModalController
    ) {
        this.activeRoute.queryParams.subscribe(res => {
            if (res && typeof res.devMode === 'string') {
                this.dataService.devMode = true;
            }
        });

        this.events.subscribe('data:reset', () => {
            //this.form.reset();
        });

        console.log(this.dataService?.configuration?.portal?.order_steps);
    }

    async addShadowElementStyle(element: HTMLElement, style: any) {
        if (element.shadowRoot === null) {
            element.attachShadow({ mode: 'open' });
        }

        const styleElement = document.createElement('style');
        styleElement.textContent = style;

        await element.shadowRoot.appendChild(styleElement);
    }

    ngAfterViewInit() {
        if (!this.dataService.isIE()) {
            // this.addShadowElementStyle(this.content.el, '.scroll-y { overflow: hidden !important;}')
        }
    }

    back(index) {
        const currentStep =
            this.dataService?.configuration?.portal?.order_steps[index - 1];
        this.dataService.currentHelpText = currentStep.help_text;
    }
    next(index) {
        const currentStep =
            this.dataService?.configuration?.portal?.order_steps[index + 1];
        this.dataService.currentHelpText = currentStep.help_text;

        /*if (data == 'maps') {
      this.mapsVisible = true;
    }*/

        // this.dataService.saveData();
    }
}
