import { Pipe, PipeTransform } from '@angular/core';
import {
    DomSanitizer,
    SafeHtml,
    SafeResourceUrl,
    SafeScript,
    SafeStyle,
    SafeUrl,
} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'safe',
})
export class SafePipe implements PipeTransform {
    constructor(
        protected sanitizer: DomSanitizer,
        private translate: TranslateService
    ) {}

    public transform(
        value: any,
        type: string
    ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        if (value) {
            switch (type) {
                case 'html':
                    return this.sanitizer.bypassSecurityTrustHtml(value);
                case 'style':
                    return this.sanitizer.bypassSecurityTrustStyle(value);
                case 'script':
                    return this.sanitizer.bypassSecurityTrustScript(value);
                case 'url':
                    return this.sanitizer.bypassSecurityTrustUrl(value);
                case 'resourceUrl':
                    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
                default:
                    throw new Error(
                        `${this.translate.instant('invalideType')} ${type}`
                    );
            }
        }
    }
}
