import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PortalService } from 'src/models/portal-service.model';
import { DataService } from 'src/services/data.service';

@Component({
    selector: 'app-service-options',
    templateUrl: './service-options.component.html',
    styleUrls: ['./service-options.component.scss'],
})
export class ServiceOptionsComponent implements OnInit {
    @Input() portalService: PortalService;

    selectedOptions = [];

    constructor(
        private modalCtrl: ModalController,
        public dataService: DataService
    ) {}

    ngOnInit() {}

    segmentChanged(ev) {}

    async close() {
        this.portalService.isChecked = true;
        await this.modalCtrl.dismiss();
    }
}
