<div
    *ngIf="dataService.loadingerror || !dataService.configuration"
    class="loading-error">
    <div>
        <h3>
            {{ 'error_loading_config' | translate }}
        </h3>
        <p>
            {{ 'error_loading_config_suggestion' | translate }}
        </p>

        <ion-button (click)="oldBrowser()">{{
            'Alternative browsers' | translate
        }}</ion-button>
    </div>
</div>
