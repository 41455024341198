import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import {
    popperVariation,
    TippyModule,
    tooltipVariation,
} from '@ngneat/helipopper';
import { HotToastModule } from '@ngneat/hot-toast';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FieldEditComponentModule } from 'src/components/field-edit/field-edit.module';
import { FooterComponent } from 'src/components/footer/footer.component';
import { MapHelperService } from 'src/pages/home/components/steps/step-maps/services/map-helper.service';
import { PortalInterceptor } from 'src/services/interceptors/portal.interceptor';
import { CustomTranslateLoader } from 'src/services/translation-loader.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
    declarations: [AppComponent, FooterComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            useDefaultLang: true,
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: (httpClient: HttpClient) =>
                    new CustomTranslateLoader(httpClient),
                deps: [HttpClient],
            },
        }),
        HotToastModule.forRoot({
            autoClose: true,
            dismissible: true,
        }),
        TippyModule.forRoot({
            defaultVariation: 'tooltip',
            variations: {
                tooltip: tooltipVariation,
                popper: popperVariation,
            },
        }),
        FieldEditComponentModule,
    ],
    providers: [
        MapHelperService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PortalInterceptor,
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
