import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FieldListComponentModule } from 'src/components/field-list/field-list.module';
import { ImageBoxComponentModule } from 'src/pages/home/components/steps/step-service-select/components/image-box/image-box.module';
import { ServiceOptionsComponentModule } from 'src/components/service-options/service-options.module';
import { StepFormDataComponent } from './step-form-data/step-form-data.component';
import { StepServiceSelectComponent } from './step-service-select/step-service-select.component';
import { StepSummaryComponent } from './step-summary/step-summary.component';

const components = [
    StepFormDataComponent,
    StepServiceSelectComponent,
    StepSummaryComponent,
];

@NgModule({
    imports: [
        ImageBoxComponentModule,
        TranslateModule,
        FieldListComponentModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        ServiceOptionsComponentModule,
    ],
    declarations: [...components],
    exports: [...components],
})
export class StepsModule {}
