import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceOrganisationComponent } from './service-organisation.component';
@NgModule({
    declarations: [ServiceOrganisationComponent],
    imports: [
        IonicModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        NgSelectModule,
    ],
    exports: [ServiceOrganisationComponent],
})
export class ServiceOrganisationComponentModule {}
