import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/services/data.service';

@Component({
    selector: 'app-step-form-data',
    templateUrl: './step-form-data.component.html',
    styleUrls: ['./step-form-data.component.scss'],
})
export class StepFormDataComponent implements OnInit {
    constructor(
        public dataService: DataService,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit() {
        this.dataService.formData = this.formBuilder.group({
            company: [
                this.dataService.orderData.company,
                Validators.compose([
                    Validators.maxLength(255),
                    this.findRequired('company'),
                ]),
            ],
            firstName: [
                this.dataService.orderData.firstName,
                Validators.compose([
                    Validators.maxLength(255),
                    this.findRequired('firstName'),
                ]),
            ],
            lastName: [
                this.dataService.orderData.lastName,
                Validators.compose([
                    Validators.maxLength(255),
                    this.findRequired('lastName'),
                ]),
            ],
            street: [
                this.dataService.orderData.street,
                Validators.compose([
                    Validators.maxLength(255),
                    this.findRequired('street'),
                ]),
            ],
            houseNumber: [
                this.dataService.orderData.houseNumber,
                Validators.compose([
                    //Validators.maxLength(10),
                    //Validators.pattern('[a-zA-Z0-9._-]*'),
                    this.findRequired('houseNumber'),
                ]),
            ],
            zipCode: [
                this.dataService.orderData.zipCode,
                Validators.compose([
                    // Validators.maxLength(5),
                    Validators.pattern('[0-9]*'),
                    this.findRequired('zipCode'),
                ]),
            ],
            city: [
                this.dataService.orderData.city,
                Validators.compose([
                    Validators.maxLength(255),
                    this.findRequired('city'),
                ]),
            ],
            phoneNumber: [
                this.dataService.orderData.phoneNumber,
                Validators.compose([
                    Validators.maxLength(255),
                    this.findRequired('phoneNumber'),
                ]),
            ],
            email: [
                this.dataService.orderData.email,
                Validators.compose([
                    Validators.maxLength(255),
                    this.findRequired('email'),
                ]),
            ],
        });

        this.onChanges();
    }

    findRequired(key) {
        return this.dataService.configuration?.portal?.required_customer_fields?.find(
            field => field == key
        )
            ? Validators.required
            : null;
    }

    onChanges(): void {
        this.dataService.formData.valueChanges.subscribe(val => {
            Object.assign(this.dataService.orderData, val);
        });
    }
}
