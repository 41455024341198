<ion-footer>
    <div class="left">
        <img
            class="brand-logo"
            [src]="environment.biocare ? '/assets/biocare-logo.png' : '/assets/img/Webaro-Logo-2.png'" />
    </div>
    <div class="right">
        <span> vmain </span>
    </div>
</ion-footer>
