import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PrivacyInfoComponent } from 'src/components/privacy-info/privacy-info.component';
import { DataService } from 'src/services/data.service';

@Component({
    selector: 'app-step-summary',
    templateUrl: './step-summary.component.html',
    styleUrls: ['./step-summary.component.scss'],
})
export class StepSummaryComponent implements OnInit {
    constructor(
        public dataService: DataService,
        private modalCtrl: ModalController
    ) {}

    ngOnInit() {}

    optionSelected(ev) {
        let isChecked = false;

        ev.options.forEach(option => {
            if (option.isChecked) {
                isChecked = true;
            }
        });

        return isChecked;
    }

    async openAGBModal() {
        const modal = await this.modalCtrl.create({
            component: PrivacyInfoComponent,
            componentProps: {},
        });
        await modal.present();
    }
}
