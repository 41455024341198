import { DataService } from 'src/services/data.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-info',
    templateUrl: './privacy-info.component.html',
    styleUrls: ['./privacy-info.component.scss'],
})
export class PrivacyInfoComponent implements OnInit {
    mode = 'agbs';
    text: any;
    constructor(
        private dataService: DataService,
        private modalCtrl: ModalController
    ) {}

    ngOnInit() {
        this.segmentChanged({ detail: { value: 'agbs' } });
    }

    segmentChanged(ev) {
        switch (ev.detail.value) {
            case 'agbs':
                this.text =
                    this.dataService.configuration.portal
                        .terms_of_service_page || '';
                break;
            case 'privacy':
                this.text =
                    this.dataService.configuration.portal.privacy_policy_page ||
                    '';
                break;

            case 'imprint':
                this.text =
                    this.dataService.configuration.portal.imprint_page || '';
                break;
        }
    }

    close() {
        this.modalCtrl.dismiss();
    }
}
