<div class="card">
    <h2>{{ 'Summary' | translate }}</h2>
    <p class="header">
        {{ 'order_info' | translate }}
        <strong>{{ 'request' | translate }}</strong
        >.
    </p>
    <div class="ion-margin-top ion-margin-bottom">
        <ion-item lines="none">
            <ion-checkbox
                slot="start"
                [(ngModel)]="dataService.agbAccepted"></ion-checkbox>
            <ion-label>
                {{ 'accept_policy' | translate }}
            </ion-label>
            <ion-button
                style="z-index: 1000"
                slot="end"
                (click)="openAGBModal()"
                >{{ 'read' | translate }}</ion-button
            >
        </ion-item>
        <p
            *ngIf="!dataService.agbAccepted"
            class="red">
            {{ 'please_accept_policy' | translate }}
        </p>
    </div>
</div>
<div class="card">
    <h2>
        {{ 'Service' | translate }}
    </h2>
    <div *ngFor="let orderDataService of dataService.orderDataServices">
        <h2
            class="mb-0 pb-0"
            *ngIf="
                orderDataService.isChecked && orderDataService.service?.title
            ">
            {{ orderDataService.service.title }}
        </h2>
        <ng-container *ngIf="optionSelected(orderDataService)">
            <h6>{{ 'Additional Options' | translate }}</h6>

            <app-service-options-table
                [portalService]="orderDataService"
                [checkbox]="false"></app-service-options-table>
        </ng-container>

        <div
            class="organization"
            *ngIf="orderDataService.portalServiceOrganizationTitle">
            <h5>{{ orderDataService.organization_label || 'Organisation' }}</h5>
            <h3>
                {{ orderDataService.portalServiceOrganizationTitle }}
            </h3>
        </div>
    </div>
</div>
<div class="card">
    <h2>
        {{ 'Data' | translate }}
    </h2>

    <p>
        <span *ngIf="dataService.orderData.company">
            {{ dataService.orderData.company }} </span
        ><br *ngIf="dataService.orderData.company" />
        {{ dataService.orderData.firstName }}
        {{ dataService.orderData.lastName }}<br />
        {{ dataService.orderData.street }}
        {{ dataService.orderData.houseNumber }}<br />
        {{ dataService.orderData.zipCode }}
        {{ dataService.orderData.city }}<br />
        {{ dataService.orderData.phoneNumber }}
    </p>
</div>

<div class="card">
    <h2 *ngIf="dataService.orderData.polygons.length">
        {{ 'Fields' | translate }}
    </h2>
    <field-list></field-list>
</div>

<div class="spacer"></div>
